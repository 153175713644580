import { CircularProgress, Stack, Typography } from '@mui/material';

const FallBack = () => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="center"
    sx={{
      minHeight: '50%',
      height: 'inherit',
      gap: '8px'
    }}
  >
    <CircularProgress size={16} />
    <Typography>Loading...</Typography>
  </Stack>
);

export default FallBack;
