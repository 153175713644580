export const config = {
  code: 0,
  message: 'success',
  purchaseorder_ids: ['303149000033189001'],
  bill: {
    branch_id: '303149000021684115',
    branch_name: 'Head Office',
    is_branch_not_editable: true,
    purchaseorder_ids: ['303149000033189001'],
    vendor_id: '303149000009402280',
    vendor_name: 'GaneshTestingVendor',
    source: 'Client',
    source_of_supply: 'KA',
    source_of_supply_formatted: 'Karnataka',
    destination_of_supply: 'KA',
    destination_of_supply_formatted: 'Karnataka',
    gst_no: '29AACCH9352H1ZA',
    reference_invoice_type: '',
    contact_category: 'business_gst',
    gst_treatment: 'business_gst',
    gst_treatment_formatted: 'Registered Business - Regular',
    tax_treatment: 'business_gst',
    tax_treatment_formatted: 'Registered Business - Regular',
    gst_return_details: {},
    invoice_conversion_type: 'invoice',
    unused_credits_payable_amount_formatted: 'Rs.0.00',
    status: 'open',
    status_formatted: 'Open',
    color_code: '',
    current_sub_status_id: '',
    current_sub_status: 'open',
    current_sub_status_formatted: 'Open',
    sub_statuses: [],
    bill_number: '',
    date: '2024-03-19',
    date_formatted: '19/03/2024',
    is_pre_gst: false,
    due_date: '2024-03-19',
    due_date_formatted: '19/03/2024',
    discount_setting: 'flat',
    tds_calculation_type: 'tds_item_level',
    is_tds_amount_in_percent: true,
    tds_percent_formatted: '0.00%',
    tds_percent: '0.00',
    tds_amount_formatted: 'Rs.0.00',
    tds_amount: 0,
    tax_account_id: '',
    payment_terms: 0,
    payment_terms_label: 'Due on Receipt',
    payment_expected_date: '',
    payment_expected_date_formatted: '',
    reference_number: 'PO30365',
    recurring_bill_id: '',
    due_by_days: '',
    due_in_days: 0,
    currency_id: '303149000000000099',
    currency_code: 'INR',
    currency_symbol: 'Rs.',
    currency_name_formatted: 'INR- Rupees',
    documents: [],
    subject_content: '',
    price_precision: 2,
    exchange_rate: 1,
    custom_fields: [
      {
        field_id: '303149000002180003',
        customfield_id: '303149000002180003',
        is_active: true,
        precision: 0,
        index: 1,
        is_mandatory: true,
        label: 'Vendor Invoice Number',
        show_on_pdf: true,
        api_name: 'cf_vendor_invoice_number',
        value_formatted: '',
        search_entity: 'bill',
        data_type: 'string',
        is_read_only: false,
        placeholder: 'cf_vendor_invoice_number',
        value: '',
        is_dependent_field: false,
        is_basecurrency_amount: true,
        max_length: 255,
        help_text: ''
      },
      {
        field_id: '303149000011852127',
        customfield_id: '303149000011852127',
        is_active: true,
        precision: 0,
        index: 2,
        is_mandatory: false,
        label: 'Payment scheduled on',
        show_on_pdf: false,
        api_name: 'cf_payment_scheduled_on',
        value_formatted: '',
        search_entity: 'bill',
        data_type: 'date',
        is_read_only: false,
        placeholder: 'cf_payment_scheduled_on',
        value: '',
        is_dependent_field: false,
        is_basecurrency_amount: true,
        max_length: 255,
        help_text: ''
      },
      {
        field_id: '303149000029275915',
        customfield_id: '303149000029275915',
        is_active: true,
        precision: 0,
        index: 3,
        is_mandatory: true,
        label: 'Actual Invoice date',
        show_on_pdf: true,
        api_name: 'cf_actual_invoice_date',
        value_formatted: '',
        search_entity: 'bill',
        data_type: 'date',
        is_read_only: false,
        placeholder: 'cf_actual_invoice_date',
        value: '',
        is_dependent_field: false,
        is_basecurrency_amount: true,
        max_length: 255,
        help_text: ''
      }
    ],
    custom_field_hash: {
      cf_vendor_invoice_number: '',
      cf_vendor_invoice_number_unformatted: '',
      cf_payment_scheduled_on: '',
      cf_payment_scheduled_on_unformatted: '',
      cf_actual_invoice_date: '',
      cf_actual_invoice_date_unformatted: ''
    },
    is_viewed_by_client: false,
    client_viewed_time: '',
    client_viewed_time_formatted: '',
    is_item_level_tax_calc: false,
    is_inclusive_tax: false,
    tax_rounding: 'entity_level',
    is_reverse_charge_applied: false,
    track_discount_in_account: true,
    is_violated: false,
    line_items: [
      {
        purchaseorder_item_id: '303149000033189003',
        quantity_decimal_place: '',
        item_id: '',
        itc_eligibility: 'eligible',
        gst_treatment_code: '',
        image_document_id: '',
        name: '',
        account_id: '303149000000232007',
        account_name: 'Radio',
        description:
          'Red FM, Delhi 93.5 FM (Jingle)\n | Delhi, Delhi\nStart Date - Apr 04, 2024\nEnd Date - Apr 04, 2024\nSecond = 10\nTime Band = Mixed Time - 7AM - 11PM\ntesting\n',
        bcy_rate: 80,
        bcy_rate_formatted: 'Rs.80.00',
        rate: 80,
        rate_formatted: 'Rs.80.00',
        sales_rate: '',
        sales_rate_formatted: '',
        purchase_rate: '',
        purchase_rate_formatted: '',
        pricebook_id: '',
        header_id: '',
        header_name: '',
        tags: [
          {
            tag_option_id: '303149000000249161',
            is_tag_mandatory: false,
            tag_name: 'Media Type',
            tag_id: '303149000000000337',
            tag_option_name: 'Radio'
          }
        ],
        quantity: 60,
        discount: 0,
        discounts: [],
        markup_percent: 0,
        markup_percent_formatted: '0.00%',
        tax_id: '303149000001447095',
        tax_exemption_id: '',
        tax_exemption_code: '',
        tax_name: 'GST(CGST+SGST)18%',
        tax_type: 'tax_group',
        tax_percentage: 18,
        taxes: [],
        line_item_taxes: [
          {
            tax_id: '303149000001413043',
            tax_name: 'CGST (9%)',
            tax_amount: 432,
            tax_amount_formatted: 'Rs.432.00'
          },
          {
            tax_id: '303149000001413041',
            tax_name: 'SGST (9%)',
            tax_amount: 432,
            tax_amount_formatted: 'Rs.432.00'
          }
        ],
        item_total: 4800,
        item_total_formatted: 'Rs.4,800.00',
        item_total_inclusive_of_tax: 5664,
        item_total_inclusive_of_tax_formatted: 'Rs.5,664.00',
        item_order: 1,
        unit: '',
        product_type: 'service',
        item_type: '',
        item_type_formatted: '',
        hsn_or_sac: '998364',
        reverse_charge_tax_id: '',
        image_name: '',
        image_type: '',
        is_billable: false,
        customer_id: '',
        customer_name: '',
        is_reference_project_associated: false,
        reference_project_name: '',
        project_id: '',
        project_name: '',
        item_custom_fields: [],
        purchase_request_items: []
      },
      {
        purchaseorder_item_id: '303149000033189005',
        quantity_decimal_place: '',
        item_id: '',
        itc_eligibility: 'eligible',
        gst_treatment_code: '',
        image_document_id: '',
        name: '',
        account_id: '303149000000232007',
        account_name: 'Radio',
        description:
          'Radio Mirchi, Mumbai 98.3 FM (Jingle)\n | Mumbai, Maharashtra\nStart Date - Apr 09, 2024\nEnd Date - Apr 09, 2024\nSecond = 10\nTime Band = Mixed Time - 7AM - 11PM\n',
        bcy_rate: 70,
        bcy_rate_formatted: 'Rs.70.00',
        rate: 70,
        rate_formatted: 'Rs.70.00',
        sales_rate: '',
        sales_rate_formatted: '',
        purchase_rate: '',
        purchase_rate_formatted: '',
        pricebook_id: '',
        header_id: '',
        header_name: '',
        tags: [
          {
            tag_option_id: '303149000000249161',
            is_tag_mandatory: false,
            tag_name: 'Media Type',
            tag_id: '303149000000000337',
            tag_option_name: 'Radio'
          }
        ],
        quantity: 40,
        discount: 0,
        discounts: [],
        markup_percent: 0,
        markup_percent_formatted: '0.00%',
        tax_id: '303149000001447095',
        tax_exemption_id: '',
        tax_exemption_code: '',
        tax_name: 'GST(CGST+SGST)18%',
        tax_type: 'tax_group',
        tax_percentage: 18,
        taxes: [],
        line_item_taxes: [
          {
            tax_id: '303149000001413043',
            tax_name: 'CGST (9%)',
            tax_amount: 252,
            tax_amount_formatted: 'Rs.252.00'
          },
          {
            tax_id: '303149000001413041',
            tax_name: 'SGST (9%)',
            tax_amount: 252,
            tax_amount_formatted: 'Rs.252.00'
          }
        ],
        item_total: 2800,
        item_total_formatted: 'Rs.2,800.00',
        item_total_inclusive_of_tax: 3304,
        item_total_inclusive_of_tax_formatted: 'Rs.3,304.00',
        item_order: 2,
        unit: '',
        product_type: 'service',
        item_type: '',
        item_type_formatted: '',
        hsn_or_sac: '998364',
        reverse_charge_tax_id: '',
        image_name: '',
        image_type: '',
        is_billable: false,
        customer_id: '',
        customer_name: '',
        is_reference_project_associated: false,
        reference_project_name: '',
        project_id: '',
        project_name: '',
        item_custom_fields: [],
        purchase_request_items: []
      }
    ],
    submitted_date: '',
    submitted_date_formatted: '',
    submitted_by: '',
    submitted_by_name: '',
    submitted_by_email: '',
    submitted_by_photo_url: '',
    submitter_id: '',
    approver_id: '',
    adjustment: 0,
    adjustment_formatted: 'Rs.0.00',
    adjustment_description: 'Adjustment',
    discount_amount_formatted: 'Rs.0.00',
    discount_amount: 0,
    discount: 0,
    discount_applied_on_amount_formatted: 'Rs.0.00',
    discount_applied_on_amount: 0,
    is_discount_before_tax: true,
    discount_account_id: '',
    discount_account_name: '',
    discount_type: '',
    sub_total: 7600,
    sub_total_formatted: 'Rs.7,600.00',
    sub_total_inclusive_of_tax: 8968,
    sub_total_inclusive_of_tax_formatted: 'Rs.8,968.00',
    tax_total: 1368,
    tax_total_formatted: 'Rs.1,368.00',
    total: 8968,
    total_formatted: 'Rs.8,968.00',
    is_line_item_invoiced: false,
    taxes: [
      {
        tax_id: '303149000001413043',
        tax_name: 'CGST (9%)',
        tax_amount: 684,
        tax_amount_formatted: 'Rs.684.00'
      },
      {
        tax_id: '303149000001413041',
        tax_name: 'SGST (9%)',
        tax_amount: 684,
        tax_amount_formatted: 'Rs.684.00'
      }
    ],
    tax_override: false,
    tds_override_preference: 'no_override',
    tds_summary: [],
    balance: 8968,
    balance_formatted: 'Rs.8,968.00',
    unprocessed_payment_amount: 0,
    unprocessed_payment_amount_formatted: 'Rs.0.00',
    is_portal_enabled: false,
    vendor_credits: [],
    journal_credits: [],
    comments: [],
    created_time: '2024-03-19T16:54:55+0530',
    created_by_id: '',
    last_modified_id: '',
    last_modified_time: '',
    warn_create_vendor_credits: true,
    reference_id: '',
    notes: '',
    terms: '',
    attachment_name: '',
    un_billed_items: {},
    template_id: '303149000000031017',
    template_name: '',
    page_width: '8.27in',
    page_height: '11.69in',
    orientation: 'portrait',
    template_type: '',
    template_type_formatted: '',
    invoices: [],
    allocated_landed_costs: [],
    unallocated_landed_costs: [],
    entity_type: 'bill',
    credit_notes: [],
    reference_bill_id: '',
    approvers_list: []
  },
  taxes: [
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: '',
      created_time: '1462884247871',
      tax_percentage_formatted: '14.5',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'Service Tax',
      tax_authority_id: '',
      input_tax_id: '303149000000000376',
      is_editable: true,
      tax_id: '303149000000044019',
      output_tax_id: '303149000000000376',
      country_code_formatted: '',
      country_code: '',
      deleted: true,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 14.5,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: '',
      created_time: '1462884247892',
      tax_percentage_formatted: '0.5',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'Swachh Bharat Cess',
      tax_authority_id: '',
      input_tax_id: '303149000000000376',
      is_editable: true,
      tax_id: '303149000000044021',
      output_tax_id: '303149000000000376',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 0.5,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: '',
      created_time: '1462944411186',
      tax_percentage_formatted: '15',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'Service Tax + Swachh Bharat',
      tax_authority_id: '',
      input_tax_id: '',
      is_editable: true,
      tax_id: '303149000000044084',
      output_tax_id: '',
      country_code_formatted: '',
      country_code: '',
      deleted: true,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax Group',
      tax_type: 'tax_group',
      tax_percentage: 15,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: '',
      created_time: '1462967418749',
      tax_percentage_formatted: '14.5',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'Service Tax + Swachh Bharat',
      tax_authority_id: '',
      input_tax_id: '',
      is_editable: true,
      tax_id: '303149000000048043',
      output_tax_id: '',
      country_code_formatted: '',
      country_code: '',
      deleted: true,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax Group',
      tax_type: 'tax_group',
      tax_percentage: 14.5,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: '',
      created_time: '1462967418749',
      tax_percentage_formatted: '14',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'Service Tax',
      tax_authority_id: '',
      input_tax_id: '303149000000000376',
      is_editable: true,
      tax_id: '303149000000048045',
      output_tax_id: '303149000000000376',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 14,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: '',
      created_time: '1463995582441',
      tax_percentage_formatted: '0.5',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'Krishi Kalyan Cess',
      tax_authority_id: '',
      input_tax_id: '303149000000000376',
      is_editable: true,
      tax_id: '303149000000065043',
      output_tax_id: '303149000000000376',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 0.5,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: '',
      created_time: '1463995732139',
      tax_percentage_formatted: '15',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'June 1st 2016 Effective',
      tax_authority_id: '',
      input_tax_id: '',
      is_editable: true,
      tax_id: '303149000000065045',
      output_tax_id: '',
      country_code_formatted: '',
      country_code: '',
      deleted: true,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax Group',
      tax_type: 'tax_group',
      tax_percentage: 15,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: '',
      created_time: '1466408042639',
      tax_percentage_formatted: '15',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'June 1st 2016 Effective (ST+SBC+KKC)',
      tax_authority_id: '',
      input_tax_id: '',
      is_editable: true,
      tax_id: '303149000000115017',
      output_tax_id: '',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax Group',
      tax_type: 'tax_group',
      tax_percentage: 15,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: '',
      created_time: '1474626090143',
      tax_percentage_formatted: '16.45',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'Tax+Vat@1.45%',
      tax_authority_id: '',
      input_tax_id: '303149000000000376',
      is_editable: true,
      tax_id: '303149000000308001',
      output_tax_id: '303149000000000376',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 16.45,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: '',
      created_time: '1474626630126',
      tax_percentage_formatted: '5',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'Vat@5%',
      tax_authority_id: '',
      input_tax_id: '303149000000000376',
      is_editable: true,
      tax_id: '303149000000308035',
      output_tax_id: '303149000000000376',
      country_code_formatted: '',
      country_code: '',
      deleted: true,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 5,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: '',
      created_time: '1475758660293',
      tax_percentage_formatted: '14.5',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'Pre June 2016 Tax',
      tax_authority_id: '',
      input_tax_id: '',
      is_editable: true,
      tax_id: '303149000000354314',
      output_tax_id: '',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax Group',
      tax_type: 'tax_group',
      tax_percentage: 14.5,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: '',
      created_time: '1476433015498',
      tax_percentage_formatted: '6',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'MVat',
      tax_authority_id: '',
      input_tax_id: '303149000000000376',
      is_editable: true,
      tax_id: '303149000000375001',
      output_tax_id: '303149000000000376',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 6,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: '',
      created_time: '1478259096732',
      tax_percentage_formatted: '12.5',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'Vat 12.5%',
      tax_authority_id: '',
      input_tax_id: '303149000000000376',
      is_editable: true,
      tax_id: '303149000000463293',
      output_tax_id: '303149000000000376',
      country_code_formatted: '',
      country_code: '',
      deleted: true,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 12.5,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: '',
      created_time: '1478521506462',
      tax_percentage_formatted: '5.5',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'Maharashtra Vat',
      tax_authority_id: '',
      input_tax_id: '303149000000000376',
      is_editable: true,
      tax_id: '303149000000471035',
      output_tax_id: '303149000000000376',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 5.5,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: '',
      created_time: '1478522976486',
      tax_percentage_formatted: '5.5',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'Vat',
      tax_authority_id: '',
      input_tax_id: '303149000000000376',
      is_editable: true,
      tax_id: '303149000000471051',
      output_tax_id: '303149000000000376',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 5.5,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: '',
      created_time: '1482232100177',
      tax_percentage_formatted: '5',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'Delhi Tax',
      tax_authority_id: '',
      input_tax_id: '303149000000000376',
      is_editable: true,
      tax_id: '303149000000596157',
      output_tax_id: '303149000000000376',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 5,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: '',
      created_time: '1482232117295',
      tax_percentage_formatted: '5',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'Delhi Vat',
      tax_authority_id: '',
      input_tax_id: '303149000000000376',
      is_editable: true,
      tax_id: '303149000000596159',
      output_tax_id: '303149000000000376',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 5,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: '',
      created_time: '1496313760609',
      tax_percentage_formatted: '14.5',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'Mangalore Vat',
      tax_authority_id: '',
      input_tax_id: '303149000000000376',
      is_editable: true,
      tax_id: '303149000001279067',
      output_tax_id: '303149000000000376',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 14.5,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: '',
      created_time: '1497933553750',
      tax_percentage_formatted: '18',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'GST',
      tax_authority_id: '',
      input_tax_id: '303149000000000376',
      is_editable: true,
      tax_id: '303149000001361017',
      output_tax_id: '303149000000000376',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 18,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'nil',
      end_date: '',
      tax_specification: 'nil',
      created_time: '1498707562531',
      tax_percentage_formatted: '0',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'Zero Tax',
      tax_authority_id: '303149000001413003',
      input_tax_id: '303149000000000376',
      is_editable: false,
      tax_id: '303149000001413005',
      output_tax_id: '303149000000000376',
      country_code_formatted: '',
      country_code: '',
      deleted: true,
      tax_specific_type_formatted: 'Nil',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 0,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'igst',
      end_date: '',
      tax_specification: 'inter',
      created_time: '1498707562748',
      tax_percentage_formatted: '5',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'IGST',
      tax_authority_id: '303149000001413003',
      input_tax_id: '303149000001837009',
      is_editable: true,
      tax_id: '303149000001413015',
      output_tax_id: '303149000001413011',
      country_code_formatted: '',
      country_code: '',
      deleted: true,
      tax_specific_type_formatted: 'IGST',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 5,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'sgst',
      end_date: '',
      tax_specification: 'intra',
      created_time: '1498707562882',
      tax_percentage_formatted: '2.5',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'SGST',
      tax_authority_id: '303149000001413003',
      input_tax_id: '303149000001837013',
      is_editable: true,
      tax_id: '303149000001413021',
      output_tax_id: '303149000001413017',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'SGST',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 2.5,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'cgst',
      end_date: '',
      tax_specification: 'intra',
      created_time: '1498707563015',
      tax_percentage_formatted: '2.5',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'CGST',
      tax_authority_id: '303149000001413003',
      input_tax_id: '303149000001837005',
      is_editable: true,
      tax_id: '303149000001413027',
      output_tax_id: '303149000001413023',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'CGST',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 2.5,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: 'intra',
      created_time: '1498707563086',
      tax_percentage_formatted: '5',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'Reduced Rate',
      tax_authority_id: '',
      input_tax_id: '',
      is_editable: true,
      tax_id: '303149000001413029',
      output_tax_id: '',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax Group',
      tax_type: 'tax_group',
      tax_percentage: 5,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'igst',
      end_date: '',
      tax_specification: 'inter',
      created_time: '1498707563171',
      tax_percentage_formatted: '12',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'IGST',
      tax_authority_id: '303149000001413003',
      input_tax_id: '303149000001837009',
      is_editable: true,
      tax_id: '303149000001413031',
      output_tax_id: '303149000001413011',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'IGST',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 12,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'sgst',
      end_date: '',
      tax_specification: 'intra',
      created_time: '1498707563248',
      tax_percentage_formatted: '6',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'SGST',
      tax_authority_id: '303149000001413003',
      input_tax_id: '303149000001837013',
      is_editable: true,
      tax_id: '303149000001413033',
      output_tax_id: '303149000001413017',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'SGST',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 6,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'cgst',
      end_date: '',
      tax_specification: 'intra',
      created_time: '1498707563338',
      tax_percentage_formatted: '6',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'CGST',
      tax_authority_id: '303149000001413003',
      input_tax_id: '303149000001837005',
      is_editable: true,
      tax_id: '303149000001413035',
      output_tax_id: '303149000001413023',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'CGST',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 6,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: 'intra',
      created_time: '1498707563418',
      tax_percentage_formatted: '12',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'Standard Rate(12)',
      tax_authority_id: '',
      input_tax_id: '',
      is_editable: true,
      tax_id: '303149000001413037',
      output_tax_id: '',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax Group',
      tax_type: 'tax_group',
      tax_percentage: 12,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'igst',
      end_date: '',
      tax_specification: 'inter',
      created_time: '1498707563520',
      tax_percentage_formatted: '18',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'IGST',
      tax_authority_id: '303149000001413003',
      input_tax_id: '303149000001837009',
      is_editable: true,
      tax_id: '303149000001413039',
      output_tax_id: '303149000001413011',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'IGST',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 18,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'sgst',
      end_date: '',
      tax_specification: 'intra',
      created_time: '1498707563607',
      tax_percentage_formatted: '9',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'SGST',
      tax_authority_id: '303149000001413003',
      input_tax_id: '303149000001837013',
      is_editable: true,
      tax_id: '303149000001413041',
      output_tax_id: '303149000001413017',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'SGST',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 9,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'cgst',
      end_date: '',
      tax_specification: 'intra',
      created_time: '1498707563701',
      tax_percentage_formatted: '9',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'CGST',
      tax_authority_id: '303149000001413003',
      input_tax_id: '303149000001837005',
      is_editable: true,
      tax_id: '303149000001413043',
      output_tax_id: '303149000001413023',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'CGST',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 9,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: 'intra',
      created_time: '1498707563764',
      tax_percentage_formatted: '18',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'Standard Rate(18)',
      tax_authority_id: '',
      input_tax_id: '',
      is_editable: true,
      tax_id: '303149000001413045',
      output_tax_id: '',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax Group',
      tax_type: 'tax_group',
      tax_percentage: 18,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'igst',
      end_date: '',
      tax_specification: 'inter',
      created_time: '1498707563855',
      tax_percentage_formatted: '9',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'IGST',
      tax_authority_id: '303149000001413003',
      input_tax_id: '303149000001837009',
      is_editable: true,
      tax_id: '303149000001413047',
      output_tax_id: '303149000001413011',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'IGST',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 9,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'sgst',
      end_date: '',
      tax_specification: 'intra',
      created_time: '1498707563947',
      tax_percentage_formatted: '14',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'SGST',
      tax_authority_id: '303149000001413003',
      input_tax_id: '303149000001837013',
      is_editable: true,
      tax_id: '303149000001413049',
      output_tax_id: '303149000001413017',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'SGST',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 14,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'cgst',
      end_date: '',
      tax_specification: 'intra',
      created_time: '1498707564029',
      tax_percentage_formatted: '14',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'CGST',
      tax_authority_id: '303149000001413003',
      input_tax_id: '303149000001837005',
      is_editable: true,
      tax_id: '303149000001413051',
      output_tax_id: '303149000001413023',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'CGST',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 14,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: 'intra',
      created_time: '1498707564111',
      tax_percentage_formatted: '28',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'Higher Rate',
      tax_authority_id: '',
      input_tax_id: '',
      is_editable: true,
      tax_id: '303149000001413053',
      output_tax_id: '',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax Group',
      tax_type: 'tax_group',
      tax_percentage: 28,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: 'intra',
      created_time: '1499153361817',
      tax_percentage_formatted: '5',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'GST for Printing',
      tax_authority_id: '',
      input_tax_id: '',
      is_editable: true,
      tax_id: '303149000001446077',
      output_tax_id: '',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax Group',
      tax_type: 'tax_group',
      tax_percentage: 5,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: 'intra',
      created_time: '1499146805566',
      tax_percentage_formatted: '18',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'GST(CGST+SGST)18%',
      tax_authority_id: '',
      input_tax_id: '',
      is_editable: true,
      tax_id: '303149000001447095',
      output_tax_id: '',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax Group',
      tax_type: 'tax_group',
      tax_percentage: 18,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'igst',
      end_date: '',
      tax_specification: 'inter',
      created_time: '1501148269563',
      tax_percentage_formatted: '5',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'IGST',
      tax_authority_id: '303149000001413003',
      input_tax_id: '303149000001837009',
      is_editable: true,
      tax_id: '303149000001587986',
      output_tax_id: '303149000001413011',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'IGST',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 5,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'igst',
      end_date: '',
      tax_specification: 'inter',
      created_time: '1501503246720',
      tax_percentage_formatted: '5',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'IGST for printing',
      tax_authority_id: '303149000001413003',
      input_tax_id: '303149000001837009',
      is_editable: true,
      tax_id: '303149000001607005',
      output_tax_id: '303149000001413011',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'IGST',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 5,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'igst',
      end_date: '',
      tax_specification: 'inter',
      created_time: '1501503414401',
      tax_percentage_formatted: '5',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'IGST for printing',
      tax_authority_id: '303149000001413003',
      input_tax_id: '303149000001837009',
      is_editable: true,
      tax_id: '303149000001607007',
      output_tax_id: '303149000001413011',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'IGST',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 5,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'igst',
      end_date: '',
      tax_specification: 'inter',
      created_time: '1501849128470',
      tax_percentage_formatted: '5',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'IGST for Printing',
      tax_authority_id: '303149000001413003',
      input_tax_id: '303149000001837009',
      is_editable: true,
      tax_id: '303149000001630643',
      output_tax_id: '303149000001413011',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'IGST',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 5,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'igst',
      end_date: '',
      tax_specification: 'inter',
      created_time: '1501849172296',
      tax_percentage_formatted: '5',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'IGST for printing',
      tax_authority_id: '303149000001413003',
      input_tax_id: '303149000001837009',
      is_editable: true,
      tax_id: '303149000001630645',
      output_tax_id: '303149000001413011',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'IGST',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 5,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: 'intra',
      created_time: '1502175172325',
      tax_percentage_formatted: '12',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'GST 12%',
      tax_authority_id: '',
      input_tax_id: '',
      is_editable: true,
      tax_id: '303149000001660335',
      output_tax_id: '',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax Group',
      tax_type: 'tax_group',
      tax_percentage: 12,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'igst',
      end_date: '',
      tax_specification: 'inter',
      created_time: '1502372048304',
      tax_percentage_formatted: '0',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'IGST0',
      tax_authority_id: '303149000001413003',
      input_tax_id: '303149000001837009',
      is_editable: false,
      tax_id: '303149000001675001',
      output_tax_id: '303149000001413011',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'IGST',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 0,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'cgst',
      end_date: '',
      tax_specification: 'intra',
      created_time: '1502372048564',
      tax_percentage_formatted: '0',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'CGST0',
      tax_authority_id: '303149000001413003',
      input_tax_id: '303149000001837005',
      is_editable: false,
      tax_id: '303149000001675003',
      output_tax_id: '303149000001413023',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'CGST',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 0,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'sgst',
      end_date: '',
      tax_specification: 'intra',
      created_time: '1502372048718',
      tax_percentage_formatted: '0',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'SGST0',
      tax_authority_id: '303149000001413003',
      input_tax_id: '303149000001837013',
      is_editable: false,
      tax_id: '303149000001675005',
      output_tax_id: '303149000001413017',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'SGST',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 0,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: 'intra',
      created_time: '1502372048823',
      tax_percentage_formatted: '0',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'GST0',
      tax_authority_id: '',
      input_tax_id: '',
      is_editable: true,
      tax_id: '303149000001675007',
      output_tax_id: '',
      country_code_formatted: '',
      country_code: '',
      deleted: true,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax Group',
      tax_type: 'tax_group',
      tax_percentage: 0,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'igst',
      end_date: '',
      tax_specification: 'inter',
      created_time: '1503380203136',
      tax_percentage_formatted: '18',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'Tax as applicable',
      tax_authority_id: '303149000001413003',
      input_tax_id: '303149000001837009',
      is_editable: true,
      tax_id: '303149000001710001',
      output_tax_id: '303149000001413011',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'IGST',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 18,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: '',
      created_time: '1503380285631',
      tax_percentage_formatted: '18',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'Tax as applicable',
      tax_authority_id: '',
      input_tax_id: '303149000000000376',
      is_editable: true,
      tax_id: '303149000001710003',
      output_tax_id: '303149000000000376',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 18,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: '',
      created_time: '1503573641354',
      tax_percentage_formatted: '6.5',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'Newspaper+Medianet',
      tax_authority_id: '',
      input_tax_id: '303149000000000376',
      is_editable: true,
      tax_id: '303149000001723015',
      output_tax_id: '303149000000000376',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 6.5,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'igst',
      end_date: '',
      tax_specification: 'inter',
      created_time: '1503573681134',
      tax_percentage_formatted: '6.18',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'Newspaper+Medianet',
      tax_authority_id: '303149000001413003',
      input_tax_id: '303149000001837009',
      is_editable: true,
      tax_id: '303149000001723017',
      output_tax_id: '303149000001413011',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'IGST',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 6.18,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'igst',
      end_date: '',
      tax_specification: 'inter',
      created_time: '1504088982566',
      tax_percentage_formatted: '0',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'Export or SEZ',
      tax_authority_id: '303149000001413003',
      input_tax_id: '303149000001837009',
      is_editable: true,
      tax_id: '303149000001737126',
      output_tax_id: '303149000001413011',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'IGST',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 0,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'igst',
      end_date: '',
      tax_specification: 'inter',
      created_time: '1531370273216',
      tax_percentage_formatted: '0',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'Inclusive of GST',
      tax_authority_id: '303149000001413003',
      input_tax_id: '303149000001837009',
      is_editable: true,
      tax_id: '303149000003579099',
      output_tax_id: '303149000001413011',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'IGST',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 0,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'igst',
      end_date: '',
      tax_specification: 'inter',
      created_time: '1531370586766',
      tax_percentage_formatted: '0',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'GST Inclusive',
      tax_authority_id: '303149000001413003',
      input_tax_id: '303149000001837009',
      is_editable: true,
      tax_id: '303149000003579103',
      output_tax_id: '303149000001413011',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'IGST',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 0,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: '',
      created_time: '1606293521287',
      tax_percentage_formatted: '5',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'GST(CGST+SGST)18%[18%]',
      tax_authority_id: '',
      input_tax_id: '303149000000000376',
      is_editable: true,
      tax_id: '303149000010912007',
      output_tax_id: '303149000000000376',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 5,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'igst',
      end_date: '',
      tax_specification: 'inter',
      created_time: '1606293582088',
      tax_percentage_formatted: '5',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'GST(CGST+SGST)5%',
      tax_authority_id: '303149000001413003',
      input_tax_id: '303149000001837009',
      is_editable: true,
      tax_id: '303149000010912011',
      output_tax_id: '303149000001413011',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'IGST',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 5,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: 'intra',
      created_time: '1606301393834',
      tax_percentage_formatted: '5',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'GST(CGST+SGST)5%',
      tax_authority_id: '',
      input_tax_id: '',
      is_editable: true,
      tax_id: '303149000010913048',
      output_tax_id: '',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax Group',
      tax_type: 'tax_group',
      tax_percentage: 5,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'igst',
      end_date: '',
      tax_specification: 'inter',
      created_time: '1641469170806',
      tax_percentage_formatted: '12',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'Taxes',
      tax_authority_id: '303149000001413003',
      input_tax_id: '303149000001837009',
      is_editable: true,
      tax_id: '303149000016129378',
      output_tax_id: '303149000001413011',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'IGST',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 12,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'igst',
      end_date: '',
      tax_specification: 'inter',
      created_time: '1641537460756',
      tax_percentage_formatted: '0',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'Tax Group',
      tax_authority_id: '303149000001413003',
      input_tax_id: '303149000001837009',
      is_editable: true,
      tax_id: '303149000016136135',
      output_tax_id: '303149000001413011',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'IGST',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 0,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: 'intra',
      created_time: '1641538576965',
      tax_percentage_formatted: '0',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'GST(CGST+SGST)0%',
      tax_authority_id: '',
      input_tax_id: '',
      is_editable: true,
      tax_id: '303149000016145001',
      output_tax_id: '',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax Group',
      tax_type: 'tax_group',
      tax_percentage: 0,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'igst',
      end_date: '',
      tax_specification: 'inter',
      created_time: '1642075887938',
      tax_percentage_formatted: '28',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'Product',
      tax_authority_id: '303149000001413003',
      input_tax_id: '303149000001837009',
      is_editable: true,
      tax_id: '303149000016235554',
      output_tax_id: '303149000001413011',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'IGST',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 28,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'tax',
      end_date: '',
      tax_specification: '',
      created_time: '1673355808272',
      tax_percentage_formatted: '5',
      is_non_advol_tax: false,
      is_value_added: false,
      tax_name: 'Export (5%)',
      tax_authority_id: '',
      input_tax_id: '',
      is_editable: true,
      tax_id: '303149000022247471',
      output_tax_id: '303149000022233127',
      country_code_formatted: 'U.S.A',
      country_code: 'US',
      deleted: false,
      tax_specific_type_formatted: 'Tax',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 5,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    },
    {
      tax_specific_type: 'igst',
      end_date: '',
      tax_specification: 'inter',
      created_time: '1689853588766',
      tax_percentage_formatted: '6',
      is_non_advol_tax: false,
      is_value_added: true,
      tax_name: 'IGST 6%',
      tax_authority_id: '303149000001413003',
      input_tax_id: '303149000001837009',
      is_editable: true,
      tax_id: '303149000026654579',
      output_tax_id: '303149000001413011',
      country_code_formatted: '',
      country_code: '',
      deleted: false,
      tax_specific_type_formatted: 'IGST',
      tax_type_formatted: 'Tax',
      tax_type: 'tax',
      tax_percentage: 6,
      start_date_formatted: '',
      start_date: '',
      end_date_formatted: ''
    }
  ],
  tds_taxes: [
    {
      end_date: '',
      tax_type_formatted: 'TDS',
      tax_type: 'tds_tax',
      tax_name: '194C',
      tax_percentage: 0.05,
      section: '194C',
      delete: 'false',
      tax_id: '303149000029296693',
      start_date: ''
    },
    {
      end_date: '',
      tax_type_formatted: 'TDS',
      tax_type: 'tds_tax',
      tax_name: '194c',
      tax_percentage: 0.05,
      section: '194C',
      delete: 'true',
      tax_id: '303149000026082897',
      start_date: ''
    },
    {
      end_date: '',
      tax_type_formatted: 'TDS',
      tax_type: 'tds_tax',
      tax_name: '194C',
      tax_percentage: 0.05,
      section: '194C',
      delete: 'true',
      tax_id: '303149000029044157',
      start_date: ''
    },
    {
      end_date: '',
      tax_type_formatted: 'TDS',
      tax_type: 'tds_tax',
      tax_name: '194C',
      tax_percentage: 0.05,
      section: '194C',
      delete: 'true',
      tax_id: '303149000026340041',
      start_date: ''
    },
    {
      end_date: '',
      tax_type_formatted: 'TDS',
      tax_type: 'tds_tax',
      tax_name: 'Commission or Brokerage',
      tax_percentage: 5,
      section: '194H',
      delete: 'true',
      tax_id: '303149000001885009',
      start_date: ''
    },
    {
      end_date: '',
      tax_type_formatted: 'TDS',
      tax_type: 'tds_tax',
      tax_name: 'Commission or Brokerage',
      tax_percentage: 5,
      section: '194H',
      delete: 'false',
      tax_id: '303149000017846746',
      start_date: ''
    },
    {
      end_date: '',
      tax_type_formatted: 'TDS',
      tax_type: 'tds_tax',
      tax_name: 'Dividend',
      tax_percentage: 10,
      section: '194',
      delete: 'false',
      tax_id: '303149000001885001',
      start_date: ''
    },
    {
      end_date: '',
      tax_type_formatted: 'TDS',
      tax_type: 'tds_tax',
      tax_name: 'Other Interest than securities',
      tax_percentage: 10,
      section: '194A',
      delete: 'false',
      tax_id: '303149000001885003',
      start_date: ''
    },
    {
      end_date: '',
      tax_type_formatted: 'TDS',
      tax_type: 'tds_tax',
      tax_name: 'Payment of contractors for Others',
      tax_percentage: 2,
      section: '194C',
      delete: 'true',
      tax_id: '303149000001885007',
      start_date: ''
    },
    {
      end_date: '',
      tax_type_formatted: 'TDS',
      tax_type: 'tds_tax',
      tax_name: 'Payment of contractors for Others',
      tax_percentage: 2,
      section: '194C',
      delete: 'false',
      tax_id: '303149000017846756',
      start_date: ''
    },
    {
      end_date: '',
      tax_type_formatted: 'TDS',
      tax_type: 'tds_tax',
      tax_name: 'Payment of contractors HUF/Indiv',
      tax_percentage: 1,
      section: '194C',
      delete: 'true',
      tax_id: '303149000001885005',
      start_date: ''
    },
    {
      end_date: '',
      tax_type_formatted: 'TDS',
      tax_type: 'tds_tax',
      tax_name: 'Payment of contractors HUF/Indiv',
      tax_percentage: 1,
      section: '194C',
      delete: 'false',
      tax_id: '303149000017846766',
      start_date: ''
    },
    {
      end_date: '',
      tax_type_formatted: 'TDS',
      tax_type: 'tds_tax',
      tax_name: 'Professional Fees',
      tax_percentage: 10,
      section: '194J',
      delete: 'true',
      tax_id: '303149000001885013',
      start_date: ''
    },
    {
      end_date: '',
      tax_type_formatted: 'TDS',
      tax_type: 'tds_tax',
      tax_name: 'Professional Fees',
      tax_percentage: 10,
      section: '194J',
      delete: 'false',
      tax_id: '303149000017846950',
      start_date: ''
    },
    {
      end_date: '',
      tax_type_formatted: 'TDS',
      tax_type: 'tds_tax',
      tax_name: 'professional fees',
      tax_percentage: 5.5,
      section: '194J',
      delete: 'false',
      tax_id: '303149000028887987',
      start_date: ''
    },
    {
      end_date: '',
      tax_type_formatted: 'TDS',
      tax_type: 'tds_tax',
      tax_name: 'Professional Fees',
      tax_percentage: 10,
      section: '194J',
      delete: 'true',
      tax_id: '303149000017846776',
      start_date: ''
    },
    {
      end_date: '',
      tax_type_formatted: 'TDS',
      tax_type: 'tds_tax',
      tax_name: 'Rent on land or furniture etc',
      tax_percentage: 10,
      section: '194I',
      delete: 'true',
      tax_id: '303149000001885011',
      start_date: ''
    },
    {
      end_date: '',
      tax_type_formatted: 'TDS',
      tax_type: 'tds_tax',
      tax_name: 'Rent on land or furniture etc',
      tax_percentage: 10,
      section: '194I',
      delete: 'true',
      tax_id: '303149000017846690',
      start_date: ''
    },
    {
      end_date: '',
      tax_type_formatted: 'TDS',
      tax_type: 'tds_tax',
      tax_name: 'Rent on land or furniture etc',
      tax_percentage: 10,
      section: '194I',
      delete: 'false',
      tax_id: '303149000017846960',
      start_date: ''
    },
    {
      end_date: '',
      tax_type_formatted: 'TDS',
      tax_type: 'tds_tax',
      tax_name: 'TDS@0.1%',
      tax_percentage: 0.1,
      section: '194Q',
      delete: 'true',
      tax_id: '303149000016397335',
      start_date: ''
    },
    {
      end_date: '',
      tax_type_formatted: 'TDS',
      tax_type: 'tds_tax',
      tax_name: 'TDS@0.1%',
      tax_percentage: 0.1,
      section: '194Q',
      delete: 'false',
      tax_id: '303149000021388149',
      start_date: ''
    },
    {
      end_date: '',
      tax_type_formatted: 'TDS',
      tax_type: 'tds_tax',
      tax_name: 'TDS@1.5%',
      tax_percentage: 1.5,
      section: '194C',
      delete: 'false',
      tax_id: '303149000011385205',
      start_date: ''
    },
    {
      end_date: '',
      tax_type_formatted: 'TDS',
      tax_type: 'tds_tax',
      tax_name: 'TDS@7.5%',
      tax_percentage: 7.5,
      section: '194J',
      delete: 'false',
      tax_id: '303149000012075472',
      start_date: ''
    }
  ],
  tcs_taxes: [
    {
      end_date: '',
      tax_type_formatted: 'TCS',
      tax_type: 'tcs_tax',
      tax_name: 'TCS',
      tax_percentage: 0.1,
      section: '206C_1H_6CR',
      delete: 'false',
      tax_id: '303149000016397325',
      start_date: ''
    },
    {
      end_date: '',
      tax_type_formatted: 'TCS',
      tax_type: 'tcs_tax',
      tax_name: 'TCS',
      tax_percentage: 1,
      section: '206C_1H_6CR',
      delete: 'true',
      tax_id: '303149000016385049',
      start_date: ''
    },
    {
      end_date: '',
      tax_type_formatted: 'TCS',
      tax_type: 'tcs_tax',
      tax_name: 'TCS',
      tax_percentage: 0.1,
      section: '206C_1H_6CR',
      delete: 'true',
      tax_id: '303149000016343278',
      start_date: ''
    }
  ],
  tax_exemptions: [
    {
      tax_exemption_id: '303149000001445091',
      tax_exemption_code: 'COMMISSION PAID',
      description: '',
      type: 'item',
      type_formatted: 'Item'
    },
    {
      tax_exemption_id: '303149000001695045',
      tax_exemption_code: 'VENDOR INVOICE RAISED',
      description: '',
      type: 'item',
      type_formatted: 'Item'
    },
    {
      tax_exemption_id: '303149000004164354',
      tax_exemption_code: 'CHARITABLE TRUST,REG 12A',
      description: '',
      type: 'item',
      type_formatted: 'Item'
    },
    {
      tax_exemption_id: '303149000004164358',
      tax_exemption_code: 'CHARITABLE TRUST, REG 12A',
      description: '',
      type: 'item',
      type_formatted: 'Item'
    },
    {
      tax_exemption_id: '303149000005513075',
      tax_exemption_code: 'EXPENSE',
      description: '',
      type: 'item',
      type_formatted: 'Item'
    },
    {
      tax_exemption_id: '303149000005611193',
      tax_exemption_code: 'PRINT MEDIA',
      description: '',
      type: 'item',
      type_formatted: 'Item'
    },
    {
      tax_exemption_id: '303149000006278303',
      tax_exemption_code: 'DELIVERY',
      description: '',
      type: 'item',
      type_formatted: 'Item'
    },
    {
      tax_exemption_id: '303149000006625140',
      tax_exemption_code: 'NO TAX',
      description: '',
      type: 'item',
      type_formatted: 'Item'
    },
    {
      tax_exemption_id: '303149000008228251',
      tax_exemption_code: 'INCLUSIVE',
      description: '',
      type: 'item',
      type_formatted: 'Item'
    },
    {
      tax_exemption_id: '303149000016943397',
      tax_exemption_code: 'OVER',
      description: '',
      type: 'item',
      type_formatted: 'Item'
    },
    {
      tax_exemption_id: '303149000022101661',
      tax_exemption_code: 'ADJUSTMENT',
      description: '',
      type: 'item',
      type_formatted: 'Item'
    }
  ],
  tax_groups_details: [
    {
      tax_group_id: '303149000000044084',
      tax_id: '303149000000044019'
    },
    {
      tax_group_id: '303149000000044084',
      tax_id: '303149000000044021'
    },
    {
      tax_group_id: '303149000000048043',
      tax_id: '303149000000048045'
    },
    {
      tax_group_id: '303149000000048043',
      tax_id: '303149000000044021'
    },
    {
      tax_group_id: '303149000000065045',
      tax_id: '303149000000065043'
    },
    {
      tax_group_id: '303149000000065045',
      tax_id: '303149000000048045'
    },
    {
      tax_group_id: '303149000000065045',
      tax_id: '303149000000044021'
    },
    {
      tax_group_id: '303149000000115017',
      tax_id: '303149000000044021'
    },
    {
      tax_group_id: '303149000000115017',
      tax_id: '303149000000048045'
    },
    {
      tax_group_id: '303149000000115017',
      tax_id: '303149000000065043'
    },
    {
      tax_group_id: '303149000000354314',
      tax_id: '303149000000048045'
    },
    {
      tax_group_id: '303149000000354314',
      tax_id: '303149000000044021'
    },
    {
      tax_group_id: '303149000001413029',
      tax_id: '303149000001413027'
    },
    {
      tax_group_id: '303149000001413029',
      tax_id: '303149000001413021'
    },
    {
      tax_group_id: '303149000001413037',
      tax_id: '303149000001413035'
    },
    {
      tax_group_id: '303149000001413037',
      tax_id: '303149000001413033'
    },
    {
      tax_group_id: '303149000001413045',
      tax_id: '303149000001413043'
    },
    {
      tax_group_id: '303149000001413045',
      tax_id: '303149000001413041'
    },
    {
      tax_group_id: '303149000001413053',
      tax_id: '303149000001413051'
    },
    {
      tax_group_id: '303149000001413053',
      tax_id: '303149000001413049'
    },
    {
      tax_group_id: '303149000001446077',
      tax_id: '303149000001413027'
    },
    {
      tax_group_id: '303149000001446077',
      tax_id: '303149000001413021'
    },
    {
      tax_group_id: '303149000001447095',
      tax_id: '303149000001413043'
    },
    {
      tax_group_id: '303149000001447095',
      tax_id: '303149000001413041'
    },
    {
      tax_group_id: '303149000001660335',
      tax_id: '303149000001413035'
    },
    {
      tax_group_id: '303149000001660335',
      tax_id: '303149000001413033'
    },
    {
      tax_group_id: '303149000001675007',
      tax_id: '303149000001675003'
    },
    {
      tax_group_id: '303149000001675007',
      tax_id: '303149000001675005'
    },
    {
      tax_group_id: '303149000010913048',
      tax_id: '303149000001413027'
    },
    {
      tax_group_id: '303149000010913048',
      tax_id: '303149000001413021'
    },
    {
      tax_group_id: '303149000016145001',
      tax_id: '303149000001675003'
    },
    {
      tax_group_id: '303149000016145001',
      tax_id: '303149000001675005'
    }
  ],
  today_date: '2024-03-19',
  bills_settings: {
    default_template_id: '303149000000031017',
    adjustment_description: 'Adjustment',
    is_adjustment_required: true,
    tax_rounding_type: 'default',
    default_markup_percent: '',
    default_markup_percent_formatted: '',
    track_discount_in_account: true,
    tds_override_preference: 'no_override'
  },
  today_date_formatted: '19/03/2024',
  bills_accounts_list: [
    {
      account_type: 'other_asset',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Deferred tax assets (net)',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000020995991',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 1,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Cash Ledger : Interest',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000029048223',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: 'Cash Ledger : Interest',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Cash Ledger : CESS Interest',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029048247',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '303149000029048223',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: 'Cash Ledger : Interest',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Cash Ledger : CGST Interest',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029048235',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '303149000029048223',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: 'Cash Ledger : Interest',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Cash Ledger : IGST Interest',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029048229',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '303149000029048223',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: 'Cash Ledger : Interest',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Cash Ledger : SGST Interest',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029048241',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '303149000029048223',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Cash Ledger : Latefee',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000029048253',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: 'Cash Ledger : Latefee',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Cash Ledger : CESS Latefee',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029048277',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '303149000029048253',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: 'Cash Ledger : Latefee',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Cash Ledger : CGST Latefee',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029048265',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '303149000029048253',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: 'Cash Ledger : Latefee',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Cash Ledger : IGST Latefee',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029048259',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '303149000029048253',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: 'Cash Ledger : Latefee',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Cash Ledger : SGST Latefee',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029048271',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '303149000029048253',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Cash Ledger : Tax',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000029048133',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: 'Cash Ledger : Tax',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Cash Ledger : CESS Tax',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029048157',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '303149000029048133',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: 'Cash Ledger : Tax',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Cash Ledger : CGST Tax',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029048145',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '303149000029048133',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: 'Cash Ledger : Tax',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Cash Ledger : IGST Tax',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029048139',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '303149000029048133',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: 'Cash Ledger : Tax',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Cash Ledger : SGST Tax',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029048151',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '303149000029048133',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Deposits',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000017955026',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: 'Deposits',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Indian News Paper Society - Deposits',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000020987553',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '303149000017955026',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: 'Deposits',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Rental Deposits',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000017955032',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '303149000017955026',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: 'Rental Deposits',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Deposit Office (Leela Murthy)',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000020994283',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '303149000017955032',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: 'Rental Deposits',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'IA Accel Private Limited -Security Deposit',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029406002',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '303149000017955032',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: 'Rental Deposits',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Kolkata Office Security Deposit',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000020994289',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '303149000017955032',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: 'Rental Deposits',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Optimhire',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000020994297',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '303149000017955032',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: 'Rental Deposits',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Rental Deposit - Mild Red',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000020994303',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '303149000017955032',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: 'Rental Deposits',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Security Deposit - 91 Spring board deposits',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000017962021',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '303149000017955032',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: 'Rental Deposits',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'security deposit- 187 binnamangala, Bangalore',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000030977355',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '303149000017955032',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'GST Input C/F',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000021126791',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'GST Write off',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000028644757',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'IGST Refundable',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000020994277',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Income Tax Refund Receivable',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000020994271',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Input Tax Credits',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000001837001',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: 'Input Tax Credits',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Input CESS',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029048127',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '303149000001837001',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: 'Input Tax Credits',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Input CGST',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000001837005',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '303149000001837001',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: 'Input Tax Credits',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Input IGST',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000001837009',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '303149000001837001',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: 'Input Tax Credits',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Input SGST',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000001837013',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '303149000001837001',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Investment',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000642981',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Loans & Advances',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000017846106',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: 'Loans & Advances',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Employee Advance',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000035001',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '303149000017846106',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'MAT Credit',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000020995565',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Prepaid Expense',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000021448370',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Reverse Charge Tax Input but not due',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000001413007',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Suspense Account',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000021291693',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'TCS Receivable',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000016343266',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'TDS',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000000370',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_asset',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'TDS Receivable',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000009936001',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 2,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'fixed_asset',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Air Conditioner',
      schedule_balancesheet_category: 'other_noncurrent_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Fixed Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000021672963',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 6,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'fixed_asset',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Asset',
      schedule_balancesheet_category: 'other_noncurrent_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Fixed Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000007780049',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 6,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'fixed_asset',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Computer Laptop & Accessories',
      schedule_balancesheet_category: 'other_noncurrent_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Fixed Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000020999375',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 6,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'fixed_asset',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Furniture & Fixtures',
      schedule_balancesheet_category: 'other_noncurrent_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Fixed Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000020988043',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 6,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'fixed_asset',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Furniture and Equipment',
      schedule_balancesheet_category: 'other_noncurrent_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Fixed Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000000367',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 6,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'fixed_asset',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Non-Project expenses',
      schedule_balancesheet_category: 'other_noncurrent_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Fixed Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000397089',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 6,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'fixed_asset',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Office Equipment',
      schedule_balancesheet_category: 'other_noncurrent_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Fixed Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000020999369',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 6,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'fixed_asset',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Regional Team Rent',
      schedule_balancesheet_category: 'other_noncurrent_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Fixed Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000007381023',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 6,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'fixed_asset',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Software',
      schedule_balancesheet_category: 'other_noncurrent_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Fixed Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000021001393',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 6,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'fixed_asset',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Water Purifier',
      schedule_balancesheet_category: 'other_noncurrent_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Fixed Asset',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000021672907',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 6,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Deductions Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000026791355',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: 'Payroll-004',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Duties & Taxes',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000017577275',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Duties & Taxes',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'CGST RCM',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000021128101',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000017577275',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Duties & Taxes',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Employee Professional Tax',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000017846070',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000017577275',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Duties & Taxes',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'IGST RCM',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000021128113',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000017577275',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Duties & Taxes',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'PF Employee Contribution',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000017846064',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000017577275',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'PF Employee Contribution',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'PF  Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000017919247',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000017846064',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Duties & Taxes',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'SGST RCM',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000021128107',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000017577275',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Duties & Taxes',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'TDS Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000001540628',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000017577275',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'TDS Payable',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'TDS Deduction U/s 194Q',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000021009251',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000001540628',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'TDS Payable',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'TDS Deduction U/s192B',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000017577265',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000001540628',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'TDS Payable',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'TDS on Commission - 194H',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000017846938',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000001540628',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'TDS Payable',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'TDS on Contract - 194C',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000017846920',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000001540628',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'TDS Payable',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'TDS on Professional - 194J',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000017846932',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000001540628',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'TDS Payable',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'TDS on Rent - 194I',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000017846926',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000001540628',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Employee Reimbursements',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000000035003',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Employee Reimbursements',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Mukesh Agrawal',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000021009703',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000000035003',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Employee Reimbursements',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Other Employees(Reimbursements)',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000021012159',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000000035003',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Employee Reimbursements',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Samir Chaudhary',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000021009697',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000000035003',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Employee Reimbursements',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Syed Ismail reimbursement',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000033842093',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000000035003',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'GST Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000001837017',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'GST Payable',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Output CESS',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029048121',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000001837017',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'GST Payable',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Output CGST',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000001413023',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000001837017',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'GST Payable',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Output IGST',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000001413011',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000001837017',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'GST Payable',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Output SGST',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000001413017',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000001837017',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Hold Salary Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000026791359',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: 'Payroll-006',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Interest Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000029048163',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Interest Payable',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Interest CESS Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029048187',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000029048163',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Interest Payable',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Interest CGST Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029048175',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000029048163',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Interest Payable',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Interest IGST Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029048169',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000029048163',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Interest Payable',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Interest SGST Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029048181',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000029048163',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Latefee Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000029048193',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Latefee Payable',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Latefee CESS Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029048217',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000029048193',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Latefee Payable',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Latefee CGST Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029048205',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000029048193',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Latefee Payable',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Latefee IGST Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029048199',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000029048193',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Latefee Payable',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Latefee SGST Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029048211',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000029048193',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Net Salary Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000026791357',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: 'Payroll-005',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Opening Balance Adjustments',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000003001',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Payroll Tax Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000026791351',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: 'Payroll-002',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Provisions',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000017846650',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Provisions',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Audit Fees Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000028674131',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000017846650',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Provisions',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Equilisation levy payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000028674061',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000017846650',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Provisions',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Gratuity',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000021016375',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000017846650',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Provisions',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'MAT',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000021016381',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000017846650',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Provisions',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Provision for Income Tax',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000028644653',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000017846650',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Provisions',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'RCM Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000021127417',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000017846650',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Provisions',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Salary Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000017846142',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000017846650',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Reimbursements Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000026791349',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: 'Payroll-001',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Statutory Deductions Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000026791353',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: 'Payroll-003',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Stipend Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000017846356',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Tax Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000000376',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: true,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'TCS Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000016343272',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Unearned Revenue',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000005001',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'credit_card',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Amex Credit Card',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: true,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Credit Card',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000017942954',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 9,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'credit_card',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'CREDIT CARD',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: true,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Credit Card',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000002092521',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 9,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'credit_card',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'ICICI Credit Card',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: true,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Credit Card',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029438771',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 9,
      account_code: '0009',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'credit_card',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Infinia Credit Card',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: true,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Credit Card',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000021447172',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 9,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'credit_card',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Razorpay Credit Card-8244',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: true,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Credit Card',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000017947037',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 9,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_liability',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Loan From Directors',
      schedule_balancesheet_category: 'other_long_term_liabilites',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000021006821',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 12,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_liability',
      ParentGeneralLedgerName: 'Loan From Directors',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Loan From Director - Mukesh',
      schedule_balancesheet_category: 'other_long_term_liabilites',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000021006827',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 12,
      account_code: '',
      ParentGeneralLedgerID: '303149000021006821',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_liability',
      ParentGeneralLedgerName: 'Loan From Directors',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Loan From Director - Samir Chaudary',
      schedule_balancesheet_category: 'other_long_term_liabilites',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000021006833',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 12,
      account_code: '',
      ParentGeneralLedgerID: '303149000021006821',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'equity',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Capital Account',
      schedule_balancesheet_category: 'share_capital',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Equity',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000021001789',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 13,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'equity',
      ParentGeneralLedgerName: 'Capital Account',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Mayank Bidawatka',
      schedule_balancesheet_category: 'share_capital',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Equity',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000021006001',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 13,
      account_code: '',
      ParentGeneralLedgerID: '303149000021001789',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'equity',
      ParentGeneralLedgerName: 'Capital Account',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Mukesh Agarwal-Capital',
      schedule_balancesheet_category: 'share_capital',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Equity',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000021006005',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 13,
      account_code: '',
      ParentGeneralLedgerID: '303149000021001789',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'equity',
      ParentGeneralLedgerName: 'Capital Account',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Samir Chaudhary-Capital',
      schedule_balancesheet_category: 'share_capital',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Equity',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000021006009',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 13,
      account_code: '',
      ParentGeneralLedgerID: '303149000021001789',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'equity',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Drawings',
      schedule_balancesheet_category: 'share_capital',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Equity',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000012001',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 13,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'equity',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Opening Balance Offset',
      schedule_balancesheet_category: 'share_capital',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Equity',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000000385',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 13,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'equity',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: "Owner's Equity",
      schedule_balancesheet_category: 'share_capital',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Equity',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000000382',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 13,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'income',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Advertising Sales',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'revenue_from_operations',
      account_type_formatted: 'Income',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000000000388',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 14,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'income',
      ParentGeneralLedgerName: 'Advertising Sales',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Airport sales',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'revenue_from_operations',
      account_type_formatted: 'Income',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029687478',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 14,
      account_code: '',
      ParentGeneralLedgerID: '303149000000000388',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'income',
      ParentGeneralLedgerName: 'Advertising Sales',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Cinema Sales',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'revenue_from_operations',
      account_type_formatted: 'Income',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000352139',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 14,
      account_code: '',
      ParentGeneralLedgerID: '303149000000000388',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'income',
      ParentGeneralLedgerName: 'Advertising Sales',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Digital Sales',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'revenue_from_operations',
      account_type_formatted: 'Income',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000352141',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 14,
      account_code: '',
      ParentGeneralLedgerID: '303149000000000388',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'income',
      ParentGeneralLedgerName: 'Advertising Sales',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Influencer-Marketing Sales',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'revenue_from_operations',
      account_type_formatted: 'Income',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000028418069',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 14,
      account_code: '',
      ParentGeneralLedgerID: '303149000000000388',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'income',
      ParentGeneralLedgerName: 'Advertising Sales',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Magazine Sales',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'revenue_from_operations',
      account_type_formatted: 'Income',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000353305',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 14,
      account_code: '',
      ParentGeneralLedgerID: '303149000000000388',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'income',
      ParentGeneralLedgerName: 'Advertising Sales',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Newspaper Sales',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'revenue_from_operations',
      account_type_formatted: 'Income',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000353309',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 14,
      account_code: '',
      ParentGeneralLedgerID: '303149000000000388',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'income',
      ParentGeneralLedgerName: 'Advertising Sales',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'NonTraditional Sales',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'revenue_from_operations',
      account_type_formatted: 'Income',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000352137',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 14,
      account_code: '',
      ParentGeneralLedgerID: '303149000000000388',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'income',
      ParentGeneralLedgerName: 'Advertising Sales',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Outdoor Sales',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'revenue_from_operations',
      account_type_formatted: 'Income',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000352143',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 14,
      account_code: '',
      ParentGeneralLedgerID: '303149000000000388',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'income',
      ParentGeneralLedgerName: 'Advertising Sales',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Radio Sales',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'revenue_from_operations',
      account_type_formatted: 'Income',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000002153001',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 14,
      account_code: '',
      ParentGeneralLedgerID: '303149000000000388',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'income',
      ParentGeneralLedgerName: 'Advertising Sales',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Sports-Marketing Sales',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'revenue_from_operations',
      account_type_formatted: 'Income',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000028418061',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 14,
      account_code: '',
      ParentGeneralLedgerID: '303149000000000388',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'income',
      ParentGeneralLedgerName: 'Advertising Sales',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Television Sales',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'revenue_from_operations',
      account_type_formatted: 'Income',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000353307',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 14,
      account_code: '',
      ParentGeneralLedgerID: '303149000000000388',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'income',
      ParentGeneralLedgerName: 'Advertising Sales',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'VOLUME DISCOUNT',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'revenue_from_operations',
      account_type_formatted: 'Income',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000030415039',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 14,
      account_code: '',
      ParentGeneralLedgerID: '303149000000000388',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'income',
      ParentGeneralLedgerName: 'VOLUME DISCOUNT',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Digital Incentive',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'revenue_from_operations',
      account_type_formatted: 'Income',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000030415047',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 14,
      account_code: '',
      ParentGeneralLedgerID: '303149000030415039',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'income',
      ParentGeneralLedgerName: 'VOLUME DISCOUNT',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Newspaper Incentive',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'revenue_from_operations',
      account_type_formatted: 'Income',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000030576912',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 14,
      account_code: '',
      ParentGeneralLedgerID: '303149000030415039',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'income',
      ParentGeneralLedgerName: 'VOLUME DISCOUNT',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'NEWSPAPER INCENTIVES',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'revenue_from_operations',
      account_type_formatted: 'Income',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000030415249',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 14,
      account_code: '',
      ParentGeneralLedgerID: '303149000030415039',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'income',
      ParentGeneralLedgerName: 'VOLUME DISCOUNT',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Radio Incentive',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'revenue_from_operations',
      account_type_formatted: 'Income',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000030576908',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 14,
      account_code: '',
      ParentGeneralLedgerID: '303149000030415039',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'income',
      ParentGeneralLedgerName: 'VOLUME DISCOUNT',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'SPORTS INCENTIVES',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'revenue_from_operations',
      account_type_formatted: 'Income',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000030415043',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 14,
      account_code: '',
      ParentGeneralLedgerID: '303149000030415039',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'income',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Discount',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'revenue_from_operations',
      account_type_formatted: 'Income',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000000406',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 14,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'income',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'General Income',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'revenue_from_operations',
      account_type_formatted: 'Income',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000000391',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 14,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'income',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Interest Income',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'revenue_from_operations',
      account_type_formatted: 'Income',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000000394',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 14,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'income',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Late Fee Income',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'revenue_from_operations',
      account_type_formatted: 'Income',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000000397',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 14,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'income',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Rounding off',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'revenue_from_operations',
      account_type_formatted: 'Income',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000010001',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 14,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'income',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Shipping Charge',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'revenue_from_operations',
      account_type_formatted: 'Income',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000014001',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 14,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_income',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'bad debt recovered',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_income',
      account_type_formatted: 'Other Income',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000032908005',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 15,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_income',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Creditors Written off',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_income',
      account_type_formatted: 'Other Income',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000027923159',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 15,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_income',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Deferred tax charge/ (credit)',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_income',
      account_type_formatted: 'Other Income',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000028674506',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 15,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_income',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Interest from Incometax',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_income',
      account_type_formatted: 'Other Income',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000024969271',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 15,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_income',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Profit on sale of mutual funds',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_income',
      account_type_formatted: 'Other Income',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000028674221',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 15,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Accounting expense',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000007381689',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Administration & office Expenses',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000025501049',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Administration & office Expenses',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Business promotion expense',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000022440019',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000025501049',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Administration & office Expenses',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Business Support Expenses',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000023619400',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000025501049',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Administration & office Expenses',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Commission',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000017846598',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000025501049',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Administration & office Expenses',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Electricity Charges',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000019775036',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000025501049',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Administration & office Expenses',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'IT and Internet Expenses',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000000427',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000025501049',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Administration & office Expenses',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Legal Expense',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000010114950',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000025501049',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Administration & office Expenses',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Marketing Expenses',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000496105',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000025501049',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Administration & office Expenses',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Office Expenses',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000018182033',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000025501049',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Administration & office Expenses',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Postage & Courier Charges',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000000436',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000025501049',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Administration & office Expenses',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Printing and Stationery',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000000442',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000025501049',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Administration & office Expenses',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Professional Tax - EC',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000023534145',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000025501049',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Administration & office Expenses',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Repairs and Maintenance',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000000457',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000025501049',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Administration & office Expenses',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'security guard charges',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000032543139',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000025501049',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Administration & office Expenses',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Telephone Expense',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000000421',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000025501049',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Administration & office Expenses',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Travel & accommodation Expense',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000000418',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000025501049',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Administration & office Expenses',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Water Expense-',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000018352479',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000025501049',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Advertising And Marketing',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000000403',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Automobile Expense',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000000424',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Bad Debt',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000000439',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Bank Fees and Charges',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000000409',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Depreciation Expense',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'depreciation_amortization_expense',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000000451',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Employee Benefits & Compensation',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'employee_benefits_expense',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000017577209',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Employee Benefits & Compensation',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Employee Welfare',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000007373631',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000017577209',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Employee Welfare',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Anniversary party',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000031218295',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000007373631',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Employee Welfare',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'diwali party',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000031218307',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000007373631',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Employee Welfare',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'employee insurance',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000031302863',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000007373631',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Employee Welfare',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'employee training expenses',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000031302879',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000007373631',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Employee Welfare',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'lunch expenses',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000031298149',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000007373631',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Employee Welfare',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'other events and parties',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000031302989',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000007373631',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Employee Welfare',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Service award party',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000031218301',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000007373631',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Employee Welfare',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'snacks and beverages expenses',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000031298155',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000007373631',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Employee Welfare',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'sports event',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000031298161',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000007373631',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Employee Welfare',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Year end party',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000031218289',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000007373631',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Employee Benefits & Compensation',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'HR Expenses',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000008609105',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000017577209',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Employee Benefits & Compensation',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Salaries and wages',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000031218369',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000017577209',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Salaries and wages',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Basic Salary',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000016132232',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000031218369',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Salaries and wages',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Bonus',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000007963250',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000031218369',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Salaries and wages',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Books and Periodicals Allowance',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000032446166',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000031218369',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Salaries and wages',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Fuel Reimbursement.',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000032446172',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000031218369',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Salaries and wages',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'House Rent Allowances',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000016706159',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000031218369',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Salaries and wages',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Incentives to Employees',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000017577243',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000031218369',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Salaries and wages',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'insurance-employee contribution',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000017846136',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000031218369',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Salaries and wages',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Other Deductions',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000017846100',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000031218369',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Salaries and wages',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'PF Administration Charges',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000018025007',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000031218369',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Salaries and wages',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'PF Employer Contribution',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000018025001',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000031218369',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Salaries and wages',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Provision for Gratuity',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000028674338',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000031218369',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Salaries and wages',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Special Allowance',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000017577199',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000031218369',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Salaries and wages',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'ST Bonus',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000017846058',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000031218369',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Salaries and wages',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Stipend Charges',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000017846350',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000031218369',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Salaries and wages',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Telephone Reimbursement.',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000032446178',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000031218369',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Salaries and wages',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'uniform allowance',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000032446160',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000031218369',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Financial Cost',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000025501067',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Financial Cost',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Credit Card Charges',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000000412',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000025501067',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Financial Cost',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'interest on OD',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000032318279',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000025501067',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Interest on GST',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000028644743',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Interest on TDS',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000028644749',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Interior & Infrastructure expenses',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000009374037',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Lodging',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000032023',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Meals and Entertainment',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000000448',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'NonProject Expenses',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000472595',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Office Rent',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000017862585',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Office Rent',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'office rent - Delhi',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000007381029',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000017862585',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Office Rent',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'office rent -Bangalore new',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000031136718',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000017862585',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Office Rent',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'office rent -Bangalore old',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000031136712',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000017862585',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Office Rent',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'office rent- Mumbai',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000031136706',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000017862585',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Office Rent',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'office rent-187 binnamangala, Bangalore',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000033355453',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000017862585',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Office Supplies',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000000400',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'other direct expenses',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000031136038',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'other direct expenses',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Audit Fees',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000028674125',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000031136038',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'other direct expenses',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'debtor written off',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000031218083',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000031136038',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'other direct expenses',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Prior Period Adjustment',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000024615869',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000031136038',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'other direct expenses',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Rounding Off Expenses',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000000000460',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000031136038',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Rounding Off Expenses',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Telephone',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000292045',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000000000460',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Parking',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000055005',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Professional & consultancy Charges',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000017846808',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: 'Professional & consultancy Charges',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Consultant Expense',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000000454',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '303149000017846808',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Purchase Discounts',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000012546001',
      depth: 0,
      is_default_purchase_discount_account: true,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Rates & Taxes',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000001778085',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Reimbursement Expenses-Employees',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000019160102',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Salaries and Employee Wages',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000000445',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Software subscription Charges',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000017846362',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'expense',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Uncategorized',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000035005',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 16,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'cost_of_goods_sold',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Airport',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Cost Of Goods Sold',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000004928221',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 17,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'cost_of_goods_sold',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Cinema',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Cost Of Goods Sold',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000261001',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 17,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'cost_of_goods_sold',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Cost of Goods Sold',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Cost Of Goods Sold',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000034003',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 17,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'cost_of_goods_sold',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Digital',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Cost Of Goods Sold',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000000293167',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 17,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'cost_of_goods_sold',
      ParentGeneralLedgerName: 'Digital',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Digital Self Serve',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Cost Of Goods Sold',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000006521228',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 17,
      account_code: '',
      ParentGeneralLedgerID: '303149000000293167',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'cost_of_goods_sold',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Influencer Marketing',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Cost Of Goods Sold',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000011075285',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 17,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'cost_of_goods_sold',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Magazine',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Cost Of Goods Sold',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000232011',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 17,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'cost_of_goods_sold',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Newspaper',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Cost Of Goods Sold',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000248023',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 17,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'cost_of_goods_sold',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Nontraditional',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Cost Of Goods Sold',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000292041',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 17,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'cost_of_goods_sold',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Outdoor',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Cost Of Goods Sold',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000251188',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 17,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'cost_of_goods_sold',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Radio',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Cost Of Goods Sold',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000232007',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 17,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'cost_of_goods_sold',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Sports-Marketing',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Cost Of Goods Sold',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000032620001',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 17,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'cost_of_goods_sold',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Television',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Cost Of Goods Sold',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000232003',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 17,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_expense',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Donation',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Other Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029875193',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 18,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_expense',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Income Tax',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Other Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000028674424',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 18,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_expense',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'misc expenses',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Other Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000031136286',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 18,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_expense',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'other indirect expenses',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Other Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000031136028',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 18,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_expense',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Razorpay Commission',
      schedule_balancesheet_category: 'no_category',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Other Expense',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000021459124',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 18,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'stock',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Inventory Asset',
      schedule_balancesheet_category: 'other_current_assets',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Stock',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000000034001',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 19,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    }
  ],
  tax_accounts_list: [
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Deductions Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000026791355',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: 'Payroll-004',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Duties & Taxes',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000017577275',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Duties & Taxes',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'CGST RCM',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000021128101',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000017577275',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Duties & Taxes',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Employee Professional Tax',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000017846070',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000017577275',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Duties & Taxes',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'IGST RCM',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000021128113',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000017577275',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Duties & Taxes',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'PF Employee Contribution',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'other_expenses',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000017846064',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000017577275',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'PF Employee Contribution',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'PF  Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000017919247',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000017846064',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Duties & Taxes',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'SGST RCM',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000021128107',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000017577275',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Duties & Taxes',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'TDS Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000001540628',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000017577275',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'TDS Payable',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'TDS Deduction U/s 194Q',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000021009251',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000001540628',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'TDS Payable',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'TDS Deduction U/s192B',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000017577265',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000001540628',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'TDS Payable',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'TDS on Commission - 194H',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000017846938',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000001540628',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'TDS Payable',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'TDS on Contract - 194C',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000017846920',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000001540628',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'TDS Payable',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'TDS on Professional - 194J',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000017846932',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000001540628',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'TDS Payable',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'TDS on Rent - 194I',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000017846926',
      depth: 2,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000001540628',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Hold Salary Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000026791359',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: 'Payroll-006',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Interest Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000029048163',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Interest Payable',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Interest CESS Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029048187',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000029048163',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Interest Payable',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Interest CGST Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029048175',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000029048163',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Interest Payable',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Interest IGST Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029048169',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000029048163',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Interest Payable',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Interest SGST Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029048181',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000029048163',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Latefee Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000029048193',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Latefee Payable',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Latefee CESS Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029048217',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000029048193',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Latefee Payable',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Latefee CGST Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029048205',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000029048193',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Latefee Payable',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Latefee IGST Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029048199',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000029048193',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Latefee Payable',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Latefee SGST Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000029048211',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000029048193',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Net Salary Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000026791357',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: 'Payroll-005',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Payroll Tax Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000026791351',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: 'Payroll-002',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Provisions',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: true,
      price_precision: 2,
      account_id: '303149000017846650',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Provisions',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Audit Fees Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000028674131',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000017846650',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Provisions',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Equilisation levy payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000028674061',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000017846650',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Provisions',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Gratuity',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000021016375',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000017846650',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Provisions',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'MAT',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000021016381',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000017846650',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Provisions',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Provision for Income Tax',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000028644653',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000017846650',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Provisions',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'RCM Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000021127417',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000017846650',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: 'Provisions',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Salary Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000017846142',
      depth: 1,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '303149000017846650',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Reimbursements Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000026791349',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: 'Payroll-001',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Statutory Deductions Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000026791353',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: 'Payroll-003',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'Stipend Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000017846356',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    },
    {
      account_type: 'other_current_liability',
      ParentGeneralLedgerName: '',
      gain_or_loss_account: false,
      currency_code: 'INR',
      ignore_currency: false,
      is_accounts_payable: false,
      account_name: 'TCS Payable',
      schedule_balancesheet_category: 'other_current_liabilities',
      allow_multi_currency: false,
      schedule_profit_and_loss_category: 'no_category',
      account_type_formatted: 'Other Current Liability',
      is_primary_account: false,
      is_accounts_receivable: false,
      is_active: true,
      currency_symbol: 'Rs.',
      masked_account_no: '',
      is_disabled_master: false,
      is_default: false,
      is_parent_category: false,
      price_precision: 2,
      account_id: '303149000016343272',
      depth: 0,
      is_default_purchase_discount_account: false,
      account_type_int: 8,
      account_code: '',
      ParentGeneralLedgerID: '',
      disable_tax: false,
      is_retained_earnings: false,
      currency_id: '303149000000000099'
    }
  ],
  custom_fields: [
    {
      customfield_id: '303149000002180003',
      precision: 0,
      is_mandatory_in_sales_item: false,
      is_mandatory_in_hp: false,
      edit_on_store: false,
      show_in_all_pdf: true,
      search_entity: 'bill',
      pii_type: 'non_pii',
      placeholder: 'cf_vendor_invoice_number',
      is_inherited_value: false,
      value: '',
      max_length: 255,
      field_id: '303149000002180003',
      show_in_store: false,
      is_active: true,
      index: 1,
      show_in_hp: false,
      is_mandatory: true,
      label: 'Vendor Invoice Number',
      is_custom_field: true,
      is_mandatory_in_storefront: false,
      api_name: 'cf_vendor_invoice_number',
      data_type: 'string',
      is_dependent_field: false,
      help_text: ''
    },
    {
      customfield_id: '303149000011852127',
      precision: 0,
      is_mandatory_in_sales_item: false,
      is_mandatory_in_hp: false,
      edit_on_store: false,
      show_in_all_pdf: false,
      search_entity: 'bill',
      pii_type: 'non_pii',
      placeholder: 'cf_payment_scheduled_on',
      is_inherited_value: false,
      value: '',
      max_length: 255,
      field_id: '303149000011852127',
      show_in_store: false,
      is_active: true,
      index: 3,
      show_in_hp: false,
      is_mandatory: false,
      label: 'Payment scheduled on',
      is_custom_field: true,
      is_mandatory_in_storefront: false,
      api_name: 'cf_payment_scheduled_on',
      data_type: 'date',
      is_dependent_field: false,
      help_text: ''
    },
    {
      customfield_id: '303149000029275915',
      precision: 0,
      is_mandatory_in_sales_item: false,
      is_mandatory_in_hp: false,
      edit_on_store: false,
      show_in_all_pdf: true,
      search_entity: 'bill',
      pii_type: 'non_pii',
      placeholder: 'cf_actual_invoice_date',
      is_inherited_value: false,
      value: '',
      max_length: 255,
      field_id: '303149000029275915',
      show_in_store: false,
      is_active: true,
      index: 5,
      show_in_hp: false,
      is_mandatory: true,
      label: 'Actual Invoice date',
      is_custom_field: true,
      is_mandatory_in_storefront: false,
      api_name: 'cf_actual_invoice_date',
      data_type: 'date',
      is_dependent_field: false,
      help_text: ''
    }
  ],
  entity_fields: [
    {
      field_id: '',
      is_active: true,
      is_from_plugin: false,
      status: 'Active',
      show_in_all_pdf: true,
      data_type: 'string',
      data_type_formatted: 'Text Box (Single Line)',
      entity: 'bill',
      entity_formatted: 'Bill',
      index: '',
      is_mandatory: true,
      can_mark_mandatory: false,
      field_name: 'bill_number',
      field_name_formatted: 'Bill#',
      is_custom_field: false,
      can_disable: false,
      is_configure_permission: false,
      can_show_in_pdf: false,
      can_edit_system_field: true,
      label: 'Bill#',
      api_name: 'bill_number'
    },
    {
      field_id: '',
      is_active: true,
      is_from_plugin: false,
      status: 'Active',
      show_in_all_pdf: true,
      data_type: 'date',
      data_type_formatted: 'Date',
      entity: 'bill',
      entity_formatted: 'Bill',
      index: '',
      is_mandatory: true,
      can_mark_mandatory: false,
      field_name: 'bill_date',
      field_name_formatted: 'Bill Date',
      is_custom_field: false,
      can_disable: false,
      is_configure_permission: false,
      can_show_in_pdf: false,
      can_edit_system_field: true,
      label: 'Bill Date',
      api_name: 'bill_date'
    },
    {
      field_id: '',
      is_active: false,
      is_from_plugin: false,
      status: 'Inactive',
      show_in_all_pdf: false,
      data_type: 'string',
      data_type_formatted: 'Text Box (Single Line)',
      entity: 'bill',
      entity_formatted: 'Bill',
      index: '',
      is_mandatory: false,
      can_mark_mandatory: false,
      field_name: 'subject',
      field_name_formatted: 'Subject',
      is_custom_field: false,
      can_disable: true,
      is_configure_permission: false,
      can_show_in_pdf: true,
      can_edit_system_field: false,
      label: 'Subject',
      api_name: 'subject'
    },
    {
      field_id: '',
      is_active: true,
      is_from_plugin: false,
      status: 'Active',
      show_in_all_pdf: false,
      data_type: 'multiline',
      data_type_formatted: 'Text Box (Multi-line)',
      entity: 'bill',
      entity_formatted: 'Bill',
      index: '',
      is_mandatory: false,
      can_mark_mandatory: false,
      field_name: 'notes',
      field_name_formatted: 'Notes',
      is_custom_field: false,
      can_disable: true,
      is_configure_permission: false,
      can_show_in_pdf: true,
      can_edit_system_field: false,
      label: 'Notes',
      api_name: 'notes'
    },
    {
      customfield_id: '303149000002180003',
      precision: 0,
      is_mandatory_in_sales_item: false,
      is_mandatory_in_hp: false,
      edit_on_store: false,
      show_in_all_pdf: true,
      search_entity: 'bill',
      pii_type: 'non_pii',
      placeholder: 'cf_vendor_invoice_number',
      is_inherited_value: false,
      value: '',
      max_length: 255,
      field_id: '303149000002180003',
      show_in_store: false,
      is_active: true,
      index: 1,
      show_in_hp: false,
      is_mandatory: true,
      label: 'Vendor Invoice Number',
      is_custom_field: true,
      is_mandatory_in_storefront: false,
      api_name: 'cf_vendor_invoice_number',
      data_type: 'string',
      is_dependent_field: false,
      help_text: ''
    },
    {
      customfield_id: '303149000011852127',
      precision: 0,
      is_mandatory_in_sales_item: false,
      is_mandatory_in_hp: false,
      edit_on_store: false,
      show_in_all_pdf: false,
      search_entity: 'bill',
      pii_type: 'non_pii',
      placeholder: 'cf_payment_scheduled_on',
      is_inherited_value: false,
      value: '',
      max_length: 255,
      field_id: '303149000011852127',
      show_in_store: false,
      is_active: true,
      index: 3,
      show_in_hp: false,
      is_mandatory: false,
      label: 'Payment scheduled on',
      is_custom_field: true,
      is_mandatory_in_storefront: false,
      api_name: 'cf_payment_scheduled_on',
      data_type: 'date',
      is_dependent_field: false,
      help_text: ''
    },
    {
      customfield_id: '303149000029275915',
      precision: 0,
      is_mandatory_in_sales_item: false,
      is_mandatory_in_hp: false,
      edit_on_store: false,
      show_in_all_pdf: true,
      search_entity: 'bill',
      pii_type: 'non_pii',
      placeholder: 'cf_actual_invoice_date',
      is_inherited_value: false,
      value: '',
      max_length: 255,
      field_id: '303149000029275915',
      show_in_store: false,
      is_active: true,
      index: 5,
      show_in_hp: false,
      is_mandatory: true,
      label: 'Actual Invoice date',
      is_custom_field: true,
      is_mandatory_in_storefront: false,
      api_name: 'cf_actual_invoice_date',
      data_type: 'date',
      is_dependent_field: false,
      help_text: ''
    }
  ],
  currencies: [
    {
      currency_id: '303149000003784003',
      currency_code: 'AED',
      currency_name: 'Dirhams',
      currency_name_formatted: 'AED- Dirhams',
      currency_symbol: 'AED',
      price_precision: 2,
      currency_format: '1,234,567.89',
      currency_formatter: {
        decimal_separator: '.',
        number_separator: ','
      },
      is_base_currency: false,
      exchange_rate: 0,
      effective_date: '',
      effective_date_formatted: ''
    },
    {
      currency_id: '303149000000000105',
      currency_code: 'AUD',
      currency_name: 'Dollars',
      currency_name_formatted: 'AUD- Dollars',
      currency_symbol: '$',
      price_precision: 2,
      currency_format: '1,234,567.89',
      currency_formatter: {
        decimal_separator: '.',
        number_separator: ','
      },
      is_base_currency: false,
      exchange_rate: 0,
      effective_date: '',
      effective_date_formatted: ''
    },
    {
      currency_id: '303149000000000101',
      currency_code: 'CAD',
      currency_name: 'Dollars',
      currency_name_formatted: 'CAD- Dollars',
      currency_symbol: '$',
      price_precision: 2,
      currency_format: '1,234,567.89',
      currency_formatter: {
        decimal_separator: '.',
        number_separator: ','
      },
      is_base_currency: false,
      exchange_rate: 0,
      effective_date: '',
      effective_date_formatted: ''
    },
    {
      currency_id: '303149000000000113',
      currency_code: 'CNY',
      currency_name: 'Yuan Renminbi',
      currency_name_formatted: 'CNY- Yuan Renminbi',
      currency_symbol: 'CNY',
      price_precision: 2,
      currency_format: '1,234,567.89',
      currency_formatter: {
        decimal_separator: '.',
        number_separator: ','
      },
      is_base_currency: false,
      exchange_rate: 0,
      effective_date: '',
      effective_date_formatted: ''
    },
    {
      currency_id: '303149000000000109',
      currency_code: 'EUR',
      currency_name: 'Euro',
      currency_name_formatted: 'EUR- Euro',
      currency_symbol: '€',
      price_precision: 2,
      currency_format: '1.234.567,89',
      currency_formatter: {
        decimal_separator: ',',
        number_separator: '.'
      },
      is_base_currency: false,
      exchange_rate: 0,
      effective_date: '',
      effective_date_formatted: ''
    },
    {
      currency_id: '303149000000000103',
      currency_code: 'GBP',
      currency_name: 'Pound Sterling',
      currency_name_formatted: 'GBP- Pound Sterling',
      currency_symbol: '£',
      price_precision: 2,
      currency_format: '1,234,567.89',
      currency_formatter: {
        decimal_separator: '.',
        number_separator: ','
      },
      is_base_currency: false,
      exchange_rate: 0,
      effective_date: '',
      effective_date_formatted: ''
    },
    {
      currency_id: '303149000000000099',
      currency_code: 'INR',
      currency_name: 'Rupees',
      currency_name_formatted: 'INR- Rupees',
      currency_symbol: 'Rs.',
      price_precision: 2,
      currency_format: '1,234,567.89',
      currency_formatter: {
        decimal_separator: '.',
        number_separator: ','
      },
      is_base_currency: true,
      exchange_rate: 0,
      effective_date: '',
      effective_date_formatted: ''
    },
    {
      currency_id: '303149000000000111',
      currency_code: 'JPY',
      currency_name: 'Japanese Yen',
      currency_name_formatted: 'JPY- Japanese Yen',
      currency_symbol: '¥',
      price_precision: 0,
      currency_format: '1,234,567',
      currency_formatter: {
        decimal_separator: '.',
        number_separator: ','
      },
      is_base_currency: false,
      exchange_rate: 0,
      effective_date: '',
      effective_date_formatted: ''
    },
    {
      currency_id: '303149000024937602',
      currency_code: 'MYR',
      currency_name: 'Malaysian Ringgit',
      currency_name_formatted: 'MYR- Malaysian Ringgit',
      currency_symbol: 'RM',
      price_precision: 2,
      currency_format: '1,234,567.89',
      currency_formatter: {
        decimal_separator: '.',
        number_separator: ','
      },
      is_base_currency: false,
      exchange_rate: 0,
      effective_date: '',
      effective_date_formatted: ''
    },
    {
      currency_id: '303149000013510003',
      currency_code: 'NPR',
      currency_name: 'Nepalese Rupee',
      currency_name_formatted: 'NPR- Nepalese Rupee',
      currency_symbol: 'Rp',
      price_precision: 2,
      currency_format: '1,234,567.89',
      currency_formatter: {
        decimal_separator: '.',
        number_separator: ','
      },
      is_base_currency: false,
      exchange_rate: 0,
      effective_date: '',
      effective_date_formatted: ''
    },
    {
      currency_id: '303149000017436162',
      currency_code: 'SGD',
      currency_name: 'Singapore Dollar',
      currency_name_formatted: 'SGD- Singapore Dollar',
      currency_symbol: '$',
      price_precision: 2,
      currency_format: '1,234,567.89',
      currency_formatter: {
        decimal_separator: '.',
        number_separator: ','
      },
      is_base_currency: false,
      exchange_rate: 0,
      effective_date: '',
      effective_date_formatted: ''
    },
    {
      currency_id: '303149000000000097',
      currency_code: 'USD',
      currency_name: 'Dollars',
      currency_name_formatted: 'USD- Dollars',
      currency_symbol: '$',
      price_precision: 2,
      currency_format: '1,234,567.89',
      currency_formatter: {
        decimal_separator: '.',
        number_separator: ','
      },
      is_base_currency: false,
      exchange_rate: 0,
      effective_date: '',
      effective_date_formatted: ''
    },
    {
      currency_id: '303149000000000107',
      currency_code: 'ZAR',
      currency_name: 'South African Rand',
      currency_name_formatted: 'ZAR- South African Rand',
      currency_symbol: 'R',
      price_precision: 2,
      currency_format: '1,234,567.89',
      currency_formatter: {
        decimal_separator: '.',
        number_separator: ','
      },
      is_base_currency: false,
      exchange_rate: 0,
      effective_date: '',
      effective_date_formatted: ''
    }
  ],
  reporting_tags: [
    {
      tag_id: '303149000000000339',
      tag_name: 'Activity Area',
      index: 4,
      associated_with: 'item',
      associated_with_formatted: 'Item',
      is_active: false,
      is_tag_mandatory: false,
      tag_options: [
        {
          tag_option_id: '303149000000312037',
          tag_option_name: 'Agra',
          is_active: true
        },
        {
          tag_option_id: '303149000000387031',
          tag_option_name: 'Ahmedabad',
          is_active: true
        },
        {
          tag_option_id: '303149000000387033',
          tag_option_name: 'Ahmednagar',
          is_active: true
        },
        {
          tag_option_id: '303149000000387039',
          tag_option_name: 'Ajmer',
          is_active: true
        },
        {
          tag_option_id: '303149000000387035',
          tag_option_name: 'Akola',
          is_active: true
        },
        {
          tag_option_id: '303149000000249183',
          tag_option_name: 'All India',
          is_active: true
        },
        {
          tag_option_id: '303149000000468383',
          tag_option_name: 'Allahabad',
          is_active: true
        },
        {
          tag_option_id: '303149000000478033',
          tag_option_name: 'Alwar',
          is_active: true
        },
        {
          tag_option_id: '303149000000387037',
          tag_option_name: 'Amravati',
          is_active: true
        },
        {
          tag_option_id: '303149000000387041',
          tag_option_name: 'Amritsar',
          is_active: true
        },
        {
          tag_option_id: '303149000000354122',
          tag_option_name: 'Andhra Pradesh',
          is_active: true
        },
        {
          tag_option_id: '303149000000387067',
          tag_option_name: 'Aurangabad',
          is_active: true
        },
        {
          tag_option_id: '303149000000249179',
          tag_option_name: 'Bangalore',
          is_active: true
        },
        {
          tag_option_id: '303149000000387069',
          tag_option_name: 'Baroda',
          is_active: true
        },
        {
          tag_option_id: '303149000000553029',
          tag_option_name: 'Beed',
          is_active: true
        },
        {
          tag_option_id: '303149000000345289',
          tag_option_name: 'Bharuch',
          is_active: true
        },
        {
          tag_option_id: '303149000000691015',
          tag_option_name: 'Bhavnagar',
          is_active: true
        },
        {
          tag_option_id: '303149000000387071',
          tag_option_name: 'Bhopal',
          is_active: true
        },
        {
          tag_option_id: '303149000000300193',
          tag_option_name: 'Bhubaneswar',
          is_active: true
        },
        {
          tag_option_id: '303149000000345297',
          tag_option_name: 'Bhuj',
          is_active: true
        },
        {
          tag_option_id: '303149000000387073',
          tag_option_name: 'Chandigarh',
          is_active: true
        },
        {
          tag_option_id: '303149000000716025',
          tag_option_name: 'Chattisgarh',
          is_active: true
        },
        {
          tag_option_id: '303149000000249181',
          tag_option_name: 'Chennai',
          is_active: true
        },
        {
          tag_option_id: '303149000000387075',
          tag_option_name: 'Coimbatore',
          is_active: true
        },
        {
          tag_option_id: '303149000000303761',
          tag_option_name: 'Daman',
          is_active: true
        },
        {
          tag_option_id: '303149000000249177',
          tag_option_name: 'Delhi',
          is_active: true
        },
        {
          tag_option_id: '303149000000345299',
          tag_option_name: 'Gandhidham',
          is_active: true
        },
        {
          tag_option_id: '303149000000303709',
          tag_option_name: 'Gangtok',
          is_active: true
        },
        {
          tag_option_id: '303149000000401001',
          tag_option_name: 'Ghaziabad',
          is_active: true
        },
        {
          tag_option_id: '303149000000312031',
          tag_option_name: 'Goa',
          is_active: true
        },
        {
          tag_option_id: '303149000000345291',
          tag_option_name: 'Gujarat',
          is_active: true
        },
        {
          tag_option_id: '303149000000503019',
          tag_option_name: 'Gurgaon',
          is_active: true
        },
        {
          tag_option_id: '303149000000343533',
          tag_option_name: 'Guwahati',
          is_active: true
        },
        {
          tag_option_id: '303149000000304001',
          tag_option_name: 'Hosur',
          is_active: true
        },
        {
          tag_option_id: '303149000000279200',
          tag_option_name: 'Hyderabad',
          is_active: true
        },
        {
          tag_option_id: '303149000000432001',
          tag_option_name: 'Imphal',
          is_active: true
        },
        {
          tag_option_id: '303149000000312041',
          tag_option_name: 'Indore',
          is_active: true
        },
        {
          tag_option_id: '303149000000312033',
          tag_option_name: 'Jabalpur',
          is_active: true
        },
        {
          tag_option_id: '303149000000387077',
          tag_option_name: 'Jaipur',
          is_active: true
        },
        {
          tag_option_id: '303149000000312035',
          tag_option_name: 'Jalandhar',
          is_active: true
        },
        {
          tag_option_id: '303149000000857369',
          tag_option_name: 'Jammu & kashmir',
          is_active: true
        },
        {
          tag_option_id: '303149000000387043',
          tag_option_name: 'Jamnagar',
          is_active: true
        },
        {
          tag_option_id: '303149000000343531',
          tag_option_name: 'Jodhpur',
          is_active: true
        },
        {
          tag_option_id: '303149000000258107',
          tag_option_name: 'Kanpur',
          is_active: true
        },
        {
          tag_option_id: '303149000000254057',
          tag_option_name: 'Kharagpur',
          is_active: true
        },
        {
          tag_option_id: '303149000000343529',
          tag_option_name: 'Kochi',
          is_active: true
        },
        {
          tag_option_id: '303149000000387047',
          tag_option_name: 'Kolhapur',
          is_active: true
        },
        {
          tag_option_id: '303149000000283017',
          tag_option_name: 'Kolkata',
          is_active: true
        },
        {
          tag_option_id: '303149000000387045',
          tag_option_name: 'Kota',
          is_active: true
        },
        {
          tag_option_id: '303149000000387079',
          tag_option_name: 'Latur',
          is_active: true
        },
        {
          tag_option_id: '303149000000312039',
          tag_option_name: 'Lucknow',
          is_active: true
        },
        {
          tag_option_id: '303149000000387049',
          tag_option_name: 'Ludhiana',
          is_active: true
        },
        {
          tag_option_id: '303149000000387051',
          tag_option_name: 'Madhurai',
          is_active: true
        },
        {
          tag_option_id: '303149000000387081',
          tag_option_name: 'Mangalore',
          is_active: true
        },
        {
          tag_option_id: '303149000000249175',
          tag_option_name: 'Mumbai',
          is_active: true
        },
        {
          tag_option_id: '303149000000260108',
          tag_option_name: 'Mysore',
          is_active: true
        },
        {
          tag_option_id: '303149000000249187',
          tag_option_name: 'Nagaland',
          is_active: true
        },
        {
          tag_option_id: '303149000000343527',
          tag_option_name: 'Nagpur',
          is_active: true
        },
        {
          tag_option_id: '303149000000387053',
          tag_option_name: 'Nanded',
          is_active: true
        },
        {
          tag_option_id: '303149000000387065',
          tag_option_name: 'Nashik',
          is_active: true
        },
        {
          tag_option_id: '303149000000345295',
          tag_option_name: 'Navsari',
          is_active: true
        },
        {
          tag_option_id: '303149000000323201',
          tag_option_name: 'Noida',
          is_active: true
        },
        {
          tag_option_id: '303149000000987459',
          tag_option_name: 'patna',
          is_active: true
        },
        {
          tag_option_id: '303149000000303429',
          tag_option_name: 'Pondicherry',
          is_active: true
        },
        {
          tag_option_id: '303149000000299119',
          tag_option_name: 'Pune',
          is_active: true
        },
        {
          tag_option_id: '303149000000387083',
          tag_option_name: 'Raipur',
          is_active: true
        },
        {
          tag_option_id: '303149000000387063',
          tag_option_name: 'Rajkot',
          is_active: true
        },
        {
          tag_option_id: '303149000000387057',
          tag_option_name: 'Salem',
          is_active: true
        },
        {
          tag_option_id: '303149000000394001',
          tag_option_name: 'Sangli',
          is_active: true
        },
        {
          tag_option_id: '303149000000719031',
          tag_option_name: 'Satara',
          is_active: true
        },
        {
          tag_option_id: '303149000000249185',
          tag_option_name: 'Shillong',
          is_active: true
        },
        {
          tag_option_id: '303149000000387055',
          tag_option_name: 'Shirdi',
          is_active: true
        },
        {
          tag_option_id: '303149000000773323',
          tag_option_name: 'Solapur',
          is_active: true
        },
        {
          tag_option_id: '303149000000310139',
          tag_option_name: 'South India',
          is_active: true
        },
        {
          tag_option_id: '303149000000394003',
          tag_option_name: 'Surat',
          is_active: true
        },
        {
          tag_option_id: '303149000000350335',
          tag_option_name: 'Tamil Nadu',
          is_active: true
        },
        {
          tag_option_id: '303149000000622764',
          tag_option_name: 'Thiruvananthapuram',
          is_active: true
        },
        {
          tag_option_id: '303149000000254055',
          tag_option_name: 'Trichy',
          is_active: true
        },
        {
          tag_option_id: '303149000000613680',
          tag_option_name: 'Trivandrum',
          is_active: true
        },
        {
          tag_option_id: '303149000000394005',
          tag_option_name: 'Udaipur',
          is_active: true
        },
        {
          tag_option_id: '303149000000688357',
          tag_option_name: 'Udaypur',
          is_active: true
        },
        {
          tag_option_id: '303149000000345293',
          tag_option_name: 'Vadodara',
          is_active: true
        },
        {
          tag_option_id: '303149000000312043',
          tag_option_name: 'Varanasi',
          is_active: true
        },
        {
          tag_option_id: '303149000000387059',
          tag_option_name: 'Vijaywada',
          is_active: true
        },
        {
          tag_option_id: '303149000000387061',
          tag_option_name: 'Vishakapatnam',
          is_active: true
        }
      ]
    },
    {
      tag_id: '303149000000000341',
      tag_name: 'Advertiser city',
      index: 5,
      associated_with: 'item',
      associated_with_formatted: 'Item',
      is_active: false,
      is_tag_mandatory: false,
      tag_options: [
        {
          tag_option_id: '303149000000691013',
          tag_option_name: 'Ahmedabad',
          is_active: true
        },
        {
          tag_option_id: '303149000000468409',
          tag_option_name: 'Allahabad',
          is_active: true
        },
        {
          tag_option_id: '303149000000249189',
          tag_option_name: 'Bangalore',
          is_active: true
        },
        {
          tag_option_id: '303149000000436015',
          tag_option_name: 'Beijing',
          is_active: true
        },
        {
          tag_option_id: '303149000001064915',
          tag_option_name: 'bhavnagar',
          is_active: true
        },
        {
          tag_option_id: '303149000000249197',
          tag_option_name: 'Bhopal',
          is_active: true
        },
        {
          tag_option_id: '303149000000300195',
          tag_option_name: 'Bhubaneswar',
          is_active: true
        },
        {
          tag_option_id: '303149000000302007',
          tag_option_name: 'Canada',
          is_active: true
        },
        {
          tag_option_id: '303149000000716027',
          tag_option_name: 'Chattisgarh',
          is_active: true
        },
        {
          tag_option_id: '303149000000249195',
          tag_option_name: 'Chennai',
          is_active: true
        },
        {
          tag_option_id: '303149000000432003',
          tag_option_name: 'Coimbatore',
          is_active: true
        },
        {
          tag_option_id: '303149000000249193',
          tag_option_name: 'Delhi',
          is_active: true
        },
        {
          tag_option_id: '303149000000303711',
          tag_option_name: 'Gangtok',
          is_active: true
        },
        {
          tag_option_id: '303149000001079519',
          tag_option_name: 'ghaziabad',
          is_active: true
        },
        {
          tag_option_id: '303149000000266021',
          tag_option_name: 'Gurgaon',
          is_active: true
        },
        {
          tag_option_id: '303149000000704069',
          tag_option_name: 'Guwahati',
          is_active: true
        },
        {
          tag_option_id: '303149000000260110',
          tag_option_name: 'Hassan',
          is_active: true
        },
        {
          tag_option_id: '303149000000304003',
          tag_option_name: 'Hosur',
          is_active: true
        },
        {
          tag_option_id: '303149000000279202',
          tag_option_name: 'Hyderabad',
          is_active: true
        },
        {
          tag_option_id: '303149000000432005',
          tag_option_name: 'Imphal',
          is_active: true
        },
        {
          tag_option_id: '303149000000308327',
          tag_option_name: 'International',
          is_active: true
        },
        {
          tag_option_id: '303149000000319019',
          tag_option_name: 'Jaipur',
          is_active: true
        },
        {
          tag_option_id: '303149000000258109',
          tag_option_name: 'Kanpur',
          is_active: true
        },
        {
          tag_option_id: '303149000000662113',
          tag_option_name: 'Kochi',
          is_active: true
        },
        {
          tag_option_id: '303149000000498011',
          tag_option_name: 'Kolkata',
          is_active: true
        },
        {
          tag_option_id: '303149000000310141',
          tag_option_name: 'Lithuania',
          is_active: true
        },
        {
          tag_option_id: '303149000000249191',
          tag_option_name: 'Mumbai',
          is_active: true
        },
        {
          tag_option_id: '303149000000303551',
          tag_option_name: 'Mysore',
          is_active: true
        },
        {
          tag_option_id: '303149000000300277',
          tag_option_name: 'Netherlands',
          is_active: true
        },
        {
          tag_option_id: '303149000000303431',
          tag_option_name: 'Pondicherry',
          is_active: true
        },
        {
          tag_option_id: '303149000000262009',
          tag_option_name: 'Pune',
          is_active: true
        },
        {
          tag_option_id: '303149000000671073',
          tag_option_name: 'Salem',
          is_active: true
        },
        {
          tag_option_id: '303149000001079423',
          tag_option_name: 'San Francisco',
          is_active: true
        },
        {
          tag_option_id: '303149000000773325',
          tag_option_name: 'Solapur',
          is_active: true
        },
        {
          tag_option_id: '303149000000275182',
          tag_option_name: 'Taiwan',
          is_active: true
        },
        {
          tag_option_id: '303149000000622766',
          tag_option_name: 'Thiruvananthapuram',
          is_active: true
        },
        {
          tag_option_id: '303149000000635001',
          tag_option_name: 'Tirunelveli',
          is_active: true
        },
        {
          tag_option_id: '303149000000688363',
          tag_option_name: 'Udaipur',
          is_active: true
        },
        {
          tag_option_id: '303149000000688361',
          tag_option_name: 'Udaypur',
          is_active: true
        },
        {
          tag_option_id: '303149000000350015',
          tag_option_name: 'Varanasi',
          is_active: true
        }
      ]
    },
    {
      tag_id: '303149000000000345',
      tag_name: 'Client Manager',
      index: 7,
      associated_with: 'item',
      associated_with_formatted: 'Item',
      is_active: false,
      is_tag_mandatory: false,
      tag_options: [
        {
          tag_option_id: '303149000001840001',
          tag_option_name: 'Abhineet',
          is_active: true
        },
        {
          tag_option_id: '303149000000503021',
          tag_option_name: 'Akash',
          is_active: true
        },
        {
          tag_option_id: '303149000004600079',
          tag_option_name: 'Akshay',
          is_active: true
        },
        {
          tag_option_id: '303149000000251007',
          tag_option_name: 'Anjana',
          is_active: true
        },
        {
          tag_option_id: '303149000000688359',
          tag_option_name: 'Anmol',
          is_active: true
        },
        {
          tag_option_id: '303149000003156441',
          tag_option_name: 'Arpita',
          is_active: true
        },
        {
          tag_option_id: '303149000002304809',
          tag_option_name: 'Ashish',
          is_active: true
        },
        {
          tag_option_id: '303149000000350570',
          tag_option_name: 'Chetan',
          is_active: true
        },
        {
          tag_option_id: '303149000002262043',
          tag_option_name: 'Hitesh',
          is_active: true
        },
        {
          tag_option_id: '303149000003493657',
          tag_option_name: 'Karthick',
          is_active: true
        },
        {
          tag_option_id: '303149000000305141',
          tag_option_name: 'Kasi',
          is_active: true
        },
        {
          tag_option_id: '303149000000251011',
          tag_option_name: 'Mukesh',
          is_active: true
        },
        {
          tag_option_id: '303149000001602053',
          tag_option_name: 'PG',
          is_active: true
        },
        {
          tag_option_id: '303149000001246651',
          tag_option_name: 'Prachi',
          is_active: true
        },
        {
          tag_option_id: '303149000004254619',
          tag_option_name: 'Rahul',
          is_active: true
        },
        {
          tag_option_id: '303149000000305139',
          tag_option_name: 'Sameer B',
          is_active: true
        },
        {
          tag_option_id: '303149000000251009',
          tag_option_name: 'Samir',
          is_active: true
        },
        {
          tag_option_id: '303149000001626009',
          tag_option_name: 'Shreya',
          is_active: true
        },
        {
          tag_option_id: '303149000002304811',
          tag_option_name: 'Sunil',
          is_active: true
        },
        {
          tag_option_id: '303149000002172001',
          tag_option_name: 'Tanveer',
          is_active: true
        },
        {
          tag_option_id: '303149000003512179',
          tag_option_name: 'Trishala',
          is_active: true
        },
        {
          tag_option_id: '303149000000251005',
          tag_option_name: 'Vidya',
          is_active: true
        },
        {
          tag_option_id: '303149000002417249',
          tag_option_name: 'Vijay',
          is_active: true
        }
      ]
    },
    {
      tag_id: '303149000000000343',
      tag_name: 'Client Type',
      index: 6,
      associated_with: 'item',
      associated_with_formatted: 'Item',
      is_active: false,
      is_tag_mandatory: false,
      tag_options: [
        {
          tag_option_id: '303149000000251003',
          tag_option_name: 'Agency',
          is_active: true
        },
        {
          tag_option_id: '303149000000251001',
          tag_option_name: 'Direct',
          is_active: true
        }
      ]
    },
    {
      tag_id: '303149000000000337',
      tag_name: 'Media Type',
      index: 3,
      associated_with: 'item',
      associated_with_formatted: 'Item',
      is_active: true,
      is_tag_mandatory: false,
      tag_options: [
        {
          tag_option_id: '303149000000305143',
          tag_option_name: '12th Cross',
          is_active: true
        },
        {
          tag_option_id: '303149000004578433',
          tag_option_name: 'Agency Panel',
          is_active: true
        },
        {
          tag_option_id: '303149000000249169',
          tag_option_name: 'Airline (non Magazine)',
          is_active: true
        },
        {
          tag_option_id: '303149000005072331',
          tag_option_name: 'Airport',
          is_active: true
        },
        {
          tag_option_id: '303149000000249173',
          tag_option_name: 'Cinema',
          is_active: true
        },
        {
          tag_option_id: '303149000000249167',
          tag_option_name: 'Digital',
          is_active: true
        },
        {
          tag_option_id: '303149000006521226',
          tag_option_name: 'Digital Self Serve',
          is_active: true
        },
        {
          tag_option_id: '303149000000262019',
          tag_option_name: 'Inflight Magazine',
          is_active: true
        },
        {
          tag_option_id: '303149000011064035',
          tag_option_name: 'Influencer-marketing',
          is_active: true
        },
        {
          tag_option_id: '303149000000249171',
          tag_option_name: 'Magazine',
          is_active: true
        },
        {
          tag_option_id: '303149000000283029',
          tag_option_name: 'Mobile SMS',
          is_active: true
        },
        {
          tag_option_id: '303149000000249163',
          tag_option_name: 'Newspaper',
          is_active: true
        },
        {
          tag_option_id: '303149000000283027',
          tag_option_name: 'Newspaper Inserts',
          is_active: true
        },
        {
          tag_option_id: '303149000000293047',
          tag_option_name: 'Nontraditional',
          is_active: true
        },
        {
          tag_option_id: '303149000000283025',
          tag_option_name: 'Outdoor',
          is_active: true
        },
        {
          tag_option_id: '303149000007969918',
          tag_option_name: 'Planning',
          is_active: true
        },
        {
          tag_option_id: '303149000000249161',
          tag_option_name: 'Radio',
          is_active: true
        },
        {
          tag_option_id: '303149000028418059',
          tag_option_name: 'Sports-marketing',
          is_active: true
        },
        {
          tag_option_id: '303149000000249165',
          tag_option_name: 'Television',
          is_active: true
        },
        {
          tag_option_id: '303149000007969916',
          tag_option_name: 'White Label',
          is_active: true
        }
      ]
    },
    {
      tag_id: '303149000000000349',
      tag_name: 'MSME TYPE',
      index: 9,
      associated_with: 'item',
      associated_with_formatted: 'Item',
      is_active: true,
      is_tag_mandatory: false,
      tag_options: [
        {
          tag_option_id: '303149000032270240',
          tag_option_name: 'MEDIUM',
          is_active: true
        },
        {
          tag_option_id: '303149000032270236',
          tag_option_name: 'MICRO',
          is_active: true
        },
        {
          tag_option_id: '303149000032270238',
          tag_option_name: 'SMALL',
          is_active: true
        }
      ]
    },
    {
      tag_id: '303149000000000347',
      tag_name: 'Partnerships Contact',
      index: 8,
      associated_with: 'item',
      associated_with_formatted: 'Item',
      is_active: false,
      is_tag_mandatory: false,
      tag_options: [
        {
          tag_option_id: '303149000003153025',
          tag_option_name: 'Aditya',
          is_active: true
        },
        {
          tag_option_id: '303149000002242195',
          tag_option_name: 'Ankit',
          is_active: true
        },
        {
          tag_option_id: '303149000004349089',
          tag_option_name: 'Anusha',
          is_active: true
        },
        {
          tag_option_id: '303149000004469299',
          tag_option_name: 'Aquib',
          is_active: true
        },
        {
          tag_option_id: '303149000000308329',
          tag_option_name: 'Ashish',
          is_active: true
        },
        {
          tag_option_id: '303149000001086771',
          tag_option_name: 'Awiral',
          is_active: true
        },
        {
          tag_option_id: '303149000000251013',
          tag_option_name: 'Ayaan',
          is_active: true
        },
        {
          tag_option_id: '303149000003153015',
          tag_option_name: 'Disha',
          is_active: true
        },
        {
          tag_option_id: '303149000003510597',
          tag_option_name: 'Disha Bohra',
          is_active: true
        },
        {
          tag_option_id: '303149000004732005',
          tag_option_name: 'Gaurav',
          is_active: true
        },
        {
          tag_option_id: '303149000002306205',
          tag_option_name: 'Hitesh',
          is_active: true
        },
        {
          tag_option_id: '303149000003278337',
          tag_option_name: 'Isha',
          is_active: true
        },
        {
          tag_option_id: '303149000000521409',
          tag_option_name: 'Kasi',
          is_active: true
        },
        {
          tag_option_id: '303149000000308331',
          tag_option_name: 'Madhu',
          is_active: true
        },
        {
          tag_option_id: '303149000004161371',
          tag_option_name: 'Manoj',
          is_active: true
        },
        {
          tag_option_id: '303149000000251015',
          tag_option_name: 'Mugundh',
          is_active: true
        },
        {
          tag_option_id: '303149000000308417',
          tag_option_name: 'Mukesh',
          is_active: true
        },
        {
          tag_option_id: '303149000003158491',
          tag_option_name: 'Padma',
          is_active: true
        },
        {
          tag_option_id: '303149000000251019',
          tag_option_name: 'Panarba Ghosh',
          is_active: true
        },
        {
          tag_option_id: '303149000000503023',
          tag_option_name: 'Raoul',
          is_active: true
        },
        {
          tag_option_id: '303149000004278330',
          tag_option_name: 'Raveena',
          is_active: true
        },
        {
          tag_option_id: '303149000001666639',
          tag_option_name: 'Sameer',
          is_active: true
        },
        {
          tag_option_id: '303149000000817663',
          tag_option_name: 'Shreya',
          is_active: true
        },
        {
          tag_option_id: '303149000001626011',
          tag_option_name: 'Sreeprabha',
          is_active: true
        },
        {
          tag_option_id: '303149000002412141',
          tag_option_name: 'Sunil',
          is_active: true
        },
        {
          tag_option_id: '303149000000308333',
          tag_option_name: 'Tejashwini',
          is_active: true
        },
        {
          tag_option_id: '303149000000251017',
          tag_option_name: 'Vijay',
          is_active: true
        }
      ]
    },
    {
      tag_id: '303149000000000335',
      tag_name: 'Project ID',
      index: 2,
      associated_with: 'item',
      associated_with_formatted: 'Item',
      is_active: true,
      is_tag_mandatory: false,
      tag_options: [
        {
          tag_option_id: '303149000000248001',
          tag_option_name: '218',
          is_active: true
        },
        {
          tag_option_id: '303149000000248003',
          tag_option_name: '219',
          is_active: true
        },
        {
          tag_option_id: '303149000000248005',
          tag_option_name: '220',
          is_active: true
        },
        {
          tag_option_id: '303149000000248007',
          tag_option_name: '221',
          is_active: true
        },
        {
          tag_option_id: '303149000000248009',
          tag_option_name: '222',
          is_active: true
        },
        {
          tag_option_id: '303149000000248011',
          tag_option_name: '223',
          is_active: true
        },
        {
          tag_option_id: '303149000000248013',
          tag_option_name: '224',
          is_active: true
        },
        {
          tag_option_id: '303149000000258075',
          tag_option_name: '225',
          is_active: true
        },
        {
          tag_option_id: '303149000000258077',
          tag_option_name: '226',
          is_active: true
        },
        {
          tag_option_id: '303149000000258079',
          tag_option_name: '227',
          is_active: true
        },
        {
          tag_option_id: '303149000000258081',
          tag_option_name: '228',
          is_active: true
        },
        {
          tag_option_id: '303149000000258083',
          tag_option_name: '229',
          is_active: true
        },
        {
          tag_option_id: '303149000000258085',
          tag_option_name: '230',
          is_active: true
        },
        {
          tag_option_id: '303149000000258087',
          tag_option_name: '231',
          is_active: true
        },
        {
          tag_option_id: '303149000000258089',
          tag_option_name: '232',
          is_active: true
        },
        {
          tag_option_id: '303149000000258091',
          tag_option_name: '233',
          is_active: true
        },
        {
          tag_option_id: '303149000000258093',
          tag_option_name: '234',
          is_active: true
        },
        {
          tag_option_id: '303149000000258095',
          tag_option_name: '235',
          is_active: true
        },
        {
          tag_option_id: '303149000000258111',
          tag_option_name: '236',
          is_active: true
        },
        {
          tag_option_id: '303149000000258113',
          tag_option_name: '237',
          is_active: true
        },
        {
          tag_option_id: '303149000000258115',
          tag_option_name: '238',
          is_active: true
        },
        {
          tag_option_id: '303149000000258117',
          tag_option_name: '239',
          is_active: true
        },
        {
          tag_option_id: '303149000000258119',
          tag_option_name: '240',
          is_active: true
        },
        {
          tag_option_id: '303149000000260112',
          tag_option_name: '241',
          is_active: true
        },
        {
          tag_option_id: '303149000000260114',
          tag_option_name: '242',
          is_active: true
        },
        {
          tag_option_id: '303149000000260116',
          tag_option_name: '243',
          is_active: true
        },
        {
          tag_option_id: '303149000000260118',
          tag_option_name: '244',
          is_active: true
        },
        {
          tag_option_id: '303149000000260120',
          tag_option_name: '245',
          is_active: true
        },
        {
          tag_option_id: '303149000000268043',
          tag_option_name: '246',
          is_active: true
        },
        {
          tag_option_id: '303149000000268045',
          tag_option_name: '247',
          is_active: true
        },
        {
          tag_option_id: '303149000000268047',
          tag_option_name: '248',
          is_active: true
        },
        {
          tag_option_id: '303149000000268049',
          tag_option_name: '249',
          is_active: true
        },
        {
          tag_option_id: '303149000000268051',
          tag_option_name: '250',
          is_active: true
        },
        {
          tag_option_id: '303149000000268053',
          tag_option_name: '251',
          is_active: true
        },
        {
          tag_option_id: '303149000000268055',
          tag_option_name: '252',
          is_active: true
        },
        {
          tag_option_id: '303149000000268057',
          tag_option_name: '253',
          is_active: true
        },
        {
          tag_option_id: '303149000000268059',
          tag_option_name: '254',
          is_active: true
        },
        {
          tag_option_id: '303149000000268061',
          tag_option_name: '255',
          is_active: true
        },
        {
          tag_option_id: '303149000000275184',
          tag_option_name: '256',
          is_active: true
        },
        {
          tag_option_id: '303149000000275186',
          tag_option_name: '257',
          is_active: true
        },
        {
          tag_option_id: '303149000000275188',
          tag_option_name: '258',
          is_active: true
        },
        {
          tag_option_id: '303149000000275190',
          tag_option_name: '259',
          is_active: true
        },
        {
          tag_option_id: '303149000000275192',
          tag_option_name: '260',
          is_active: true
        },
        {
          tag_option_id: '303149000000275194',
          tag_option_name: '261',
          is_active: true
        },
        {
          tag_option_id: '303149000000275196',
          tag_option_name: '262',
          is_active: true
        },
        {
          tag_option_id: '303149000000293049',
          tag_option_name: '263',
          is_active: true
        },
        {
          tag_option_id: '303149000000293051',
          tag_option_name: '264',
          is_active: true
        },
        {
          tag_option_id: '303149000000293053',
          tag_option_name: '265',
          is_active: true
        },
        {
          tag_option_id: '303149000000293055',
          tag_option_name: '266',
          is_active: true
        },
        {
          tag_option_id: '303149000000293057',
          tag_option_name: '267',
          is_active: true
        },
        {
          tag_option_id: '303149000000293059',
          tag_option_name: '268',
          is_active: true
        },
        {
          tag_option_id: '303149000000293061',
          tag_option_name: '269',
          is_active: true
        },
        {
          tag_option_id: '303149000000293063',
          tag_option_name: '270',
          is_active: true
        },
        {
          tag_option_id: '303149000000293075',
          tag_option_name: '271',
          is_active: true
        },
        {
          tag_option_id: '303149000000293077',
          tag_option_name: '272',
          is_active: true
        },
        {
          tag_option_id: '303149000000293079',
          tag_option_name: '273',
          is_active: true
        },
        {
          tag_option_id: '303149000000293081',
          tag_option_name: '274',
          is_active: true
        },
        {
          tag_option_id: '303149000000293083',
          tag_option_name: '275',
          is_active: true
        },
        {
          tag_option_id: '303149000000293085',
          tag_option_name: '276',
          is_active: true
        },
        {
          tag_option_id: '303149000000293087',
          tag_option_name: '277',
          is_active: true
        },
        {
          tag_option_id: '303149000000293089',
          tag_option_name: '278',
          is_active: true
        },
        {
          tag_option_id: '303149000000293091',
          tag_option_name: '279',
          is_active: true
        },
        {
          tag_option_id: '303149000000293093',
          tag_option_name: '280',
          is_active: true
        },
        {
          tag_option_id: '303149000000302009',
          tag_option_name: '281',
          is_active: true
        },
        {
          tag_option_id: '303149000000302011',
          tag_option_name: '282',
          is_active: true
        },
        {
          tag_option_id: '303149000000302013',
          tag_option_name: '283',
          is_active: true
        },
        {
          tag_option_id: '303149000000302015',
          tag_option_name: '284',
          is_active: true
        },
        {
          tag_option_id: '303149000000302017',
          tag_option_name: '285',
          is_active: true
        },
        {
          tag_option_id: '303149000000302019',
          tag_option_name: '286',
          is_active: true
        },
        {
          tag_option_id: '303149000000302021',
          tag_option_name: '287',
          is_active: true
        },
        {
          tag_option_id: '303149000000302023',
          tag_option_name: '288',
          is_active: true
        },
        {
          tag_option_id: '303149000000302025',
          tag_option_name: '289',
          is_active: true
        },
        {
          tag_option_id: '303149000000302027',
          tag_option_name: '290',
          is_active: true
        },
        {
          tag_option_id: '303149000000305145',
          tag_option_name: '291',
          is_active: true
        },
        {
          tag_option_id: '303149000000305147',
          tag_option_name: '292',
          is_active: true
        },
        {
          tag_option_id: '303149000000305149',
          tag_option_name: '293',
          is_active: true
        },
        {
          tag_option_id: '303149000000305151',
          tag_option_name: '294',
          is_active: true
        },
        {
          tag_option_id: '303149000000305153',
          tag_option_name: '295',
          is_active: true
        },
        {
          tag_option_id: '303149000000310365',
          tag_option_name: '296',
          is_active: true
        },
        {
          tag_option_id: '303149000000310367',
          tag_option_name: '297',
          is_active: true
        },
        {
          tag_option_id: '303149000000312997',
          tag_option_name: '298',
          is_active: true
        },
        {
          tag_option_id: '303149000000312999',
          tag_option_name: '299',
          is_active: true
        },
        {
          tag_option_id: '303149000000318001',
          tag_option_name: '300',
          is_active: true
        },
        {
          tag_option_id: '303149000000318003',
          tag_option_name: '301',
          is_active: true
        },
        {
          tag_option_id: '303149000000318005',
          tag_option_name: '302',
          is_active: true
        },
        {
          tag_option_id: '303149000000318007',
          tag_option_name: '303',
          is_active: true
        },
        {
          tag_option_id: '303149000000318009',
          tag_option_name: '304',
          is_active: true
        },
        {
          tag_option_id: '303149000000318011',
          tag_option_name: '305',
          is_active: true
        },
        {
          tag_option_id: '303149000000318013',
          tag_option_name: '306',
          is_active: true
        },
        {
          tag_option_id: '303149000000318015',
          tag_option_name: '307',
          is_active: true
        },
        {
          tag_option_id: '303149000000318017',
          tag_option_name: '308',
          is_active: true
        },
        {
          tag_option_id: '303149000000318019',
          tag_option_name: '309',
          is_active: true
        },
        {
          tag_option_id: '303149000000318021',
          tag_option_name: '310',
          is_active: true
        },
        {
          tag_option_id: '303149000000318023',
          tag_option_name: '311',
          is_active: true
        },
        {
          tag_option_id: '303149000000318025',
          tag_option_name: '312',
          is_active: true
        },
        {
          tag_option_id: '303149000000318027',
          tag_option_name: '313',
          is_active: true
        },
        {
          tag_option_id: '303149000000318029',
          tag_option_name: '314',
          is_active: true
        },
        {
          tag_option_id: '303149000000343661',
          tag_option_name: '315',
          is_active: true
        },
        {
          tag_option_id: '303149000000343663',
          tag_option_name: '316',
          is_active: true
        },
        {
          tag_option_id: '303149000000343665',
          tag_option_name: '317',
          is_active: true
        },
        {
          tag_option_id: '303149000000345283',
          tag_option_name: '318',
          is_active: true
        },
        {
          tag_option_id: '303149000000345285',
          tag_option_name: '319',
          is_active: true
        },
        {
          tag_option_id: '303149000000345287',
          tag_option_name: '320',
          is_active: true
        },
        {
          tag_option_id: '303149000000345301',
          tag_option_name: '321',
          is_active: true
        },
        {
          tag_option_id: '303149000000345303',
          tag_option_name: '322',
          is_active: true
        },
        {
          tag_option_id: '303149000000345305',
          tag_option_name: '323',
          is_active: true
        },
        {
          tag_option_id: '303149000000345307',
          tag_option_name: '324',
          is_active: true
        },
        {
          tag_option_id: '303149000000345309',
          tag_option_name: '325',
          is_active: true
        },
        {
          tag_option_id: '303149000000345311',
          tag_option_name: '326',
          is_active: true
        },
        {
          tag_option_id: '303149000000345313',
          tag_option_name: '327',
          is_active: true
        },
        {
          tag_option_id: '303149000000345315',
          tag_option_name: '328',
          is_active: true
        },
        {
          tag_option_id: '303149000000345317',
          tag_option_name: '329',
          is_active: true
        },
        {
          tag_option_id: '303149000000345319',
          tag_option_name: '330',
          is_active: true
        },
        {
          tag_option_id: '303149000000349029',
          tag_option_name: '331',
          is_active: true
        },
        {
          tag_option_id: '303149000000349031',
          tag_option_name: '332',
          is_active: true
        },
        {
          tag_option_id: '303149000000349033',
          tag_option_name: '333',
          is_active: true
        },
        {
          tag_option_id: '303149000000349035',
          tag_option_name: '334',
          is_active: true
        },
        {
          tag_option_id: '303149000000349037',
          tag_option_name: '335',
          is_active: true
        },
        {
          tag_option_id: '303149000000349039',
          tag_option_name: '336',
          is_active: true
        },
        {
          tag_option_id: '303149000000349041',
          tag_option_name: '337',
          is_active: true
        },
        {
          tag_option_id: '303149000000349043',
          tag_option_name: '338',
          is_active: true
        },
        {
          tag_option_id: '303149000000349045',
          tag_option_name: '339',
          is_active: true
        },
        {
          tag_option_id: '303149000000349047',
          tag_option_name: '340',
          is_active: true
        },
        {
          tag_option_id: '303149000000350135',
          tag_option_name: '341',
          is_active: true
        },
        {
          tag_option_id: '303149000000350137',
          tag_option_name: '342',
          is_active: true
        },
        {
          tag_option_id: '303149000000350139',
          tag_option_name: '343',
          is_active: true
        },
        {
          tag_option_id: '303149000000350141',
          tag_option_name: '344',
          is_active: true
        },
        {
          tag_option_id: '303149000000350143',
          tag_option_name: '345',
          is_active: true
        },
        {
          tag_option_id: '303149000000350145',
          tag_option_name: '346',
          is_active: true
        },
        {
          tag_option_id: '303149000000350147',
          tag_option_name: '347',
          is_active: true
        },
        {
          tag_option_id: '303149000000350149',
          tag_option_name: '348',
          is_active: true
        },
        {
          tag_option_id: '303149000000350151',
          tag_option_name: '349',
          is_active: true
        },
        {
          tag_option_id: '303149000000350153',
          tag_option_name: '350',
          is_active: true
        },
        {
          tag_option_id: '303149000000350155',
          tag_option_name: '351',
          is_active: true
        },
        {
          tag_option_id: '303149000000350157',
          tag_option_name: '352',
          is_active: true
        },
        {
          tag_option_id: '303149000000350159',
          tag_option_name: '353',
          is_active: true
        },
        {
          tag_option_id: '303149000000350161',
          tag_option_name: '354',
          is_active: true
        },
        {
          tag_option_id: '303149000000350163',
          tag_option_name: '355',
          is_active: true
        },
        {
          tag_option_id: '303149000000350165',
          tag_option_name: '356',
          is_active: true
        },
        {
          tag_option_id: '303149000000350167',
          tag_option_name: '357',
          is_active: true
        },
        {
          tag_option_id: '303149000000350169',
          tag_option_name: '358',
          is_active: true
        },
        {
          tag_option_id: '303149000000350171',
          tag_option_name: '359',
          is_active: true
        },
        {
          tag_option_id: '303149000000350173',
          tag_option_name: '360',
          is_active: true
        },
        {
          tag_option_id: '303149000000372061',
          tag_option_name: '361',
          is_active: true
        },
        {
          tag_option_id: '303149000000372063',
          tag_option_name: '362',
          is_active: true
        },
        {
          tag_option_id: '303149000000372065',
          tag_option_name: '363',
          is_active: true
        },
        {
          tag_option_id: '303149000000372067',
          tag_option_name: '364',
          is_active: true
        },
        {
          tag_option_id: '303149000000372069',
          tag_option_name: '365',
          is_active: true
        },
        {
          tag_option_id: '303149000000372071',
          tag_option_name: '366',
          is_active: true
        },
        {
          tag_option_id: '303149000000372073',
          tag_option_name: '367',
          is_active: true
        },
        {
          tag_option_id: '303149000004518007',
          tag_option_name: '368',
          is_active: true
        },
        {
          tag_option_id: '303149000004518077',
          tag_option_name: '369',
          is_active: true
        },
        {
          tag_option_id: '303149000004518013',
          tag_option_name: '370',
          is_active: true
        },
        {
          tag_option_id: '303149000004518019',
          tag_option_name: '371',
          is_active: true
        },
        {
          tag_option_id: '303149000004518021',
          tag_option_name: '372',
          is_active: true
        },
        {
          tag_option_id: '303149000004518023',
          tag_option_name: '373',
          is_active: true
        },
        {
          tag_option_id: '303149000004518025',
          tag_option_name: '374',
          is_active: true
        },
        {
          tag_option_id: '303149000004518027',
          tag_option_name: '375',
          is_active: true
        },
        {
          tag_option_id: '303149000004518029',
          tag_option_name: '376',
          is_active: true
        },
        {
          tag_option_id: '303149000004518031',
          tag_option_name: '377',
          is_active: true
        },
        {
          tag_option_id: '303149000004518033',
          tag_option_name: '378',
          is_active: true
        },
        {
          tag_option_id: '303149000004518035',
          tag_option_name: '379',
          is_active: true
        },
        {
          tag_option_id: '303149000004518037',
          tag_option_name: '380',
          is_active: true
        },
        {
          tag_option_id: '303149000004518039',
          tag_option_name: '381',
          is_active: true
        },
        {
          tag_option_id: '303149000004518041',
          tag_option_name: '382',
          is_active: true
        },
        {
          tag_option_id: '303149000004518043',
          tag_option_name: '383',
          is_active: true
        },
        {
          tag_option_id: '303149000004518045',
          tag_option_name: '384',
          is_active: true
        },
        {
          tag_option_id: '303149000004518047',
          tag_option_name: '385',
          is_active: true
        },
        {
          tag_option_id: '303149000004518049',
          tag_option_name: '386',
          is_active: true
        },
        {
          tag_option_id: '303149000004518051',
          tag_option_name: '387',
          is_active: true
        },
        {
          tag_option_id: '303149000004518053',
          tag_option_name: '388',
          is_active: true
        },
        {
          tag_option_id: '303149000004518055',
          tag_option_name: '389',
          is_active: true
        },
        {
          tag_option_id: '303149000004518059',
          tag_option_name: '390',
          is_active: true
        },
        {
          tag_option_id: '303149000014957339',
          tag_option_name: '391',
          is_active: true
        },
        {
          tag_option_id: '303149000014957343',
          tag_option_name: '392',
          is_active: true
        },
        {
          tag_option_id: '303149000014957345',
          tag_option_name: '393',
          is_active: true
        },
        {
          tag_option_id: '303149000014957347',
          tag_option_name: '394',
          is_active: true
        },
        {
          tag_option_id: '303149000014957349',
          tag_option_name: '395',
          is_active: true
        },
        {
          tag_option_id: '303149000014957351',
          tag_option_name: '396',
          is_active: true
        },
        {
          tag_option_id: '303149000014957353',
          tag_option_name: '397',
          is_active: true
        },
        {
          tag_option_id: '303149000014957355',
          tag_option_name: '398',
          is_active: true
        },
        {
          tag_option_id: '303149000014957357',
          tag_option_name: '399',
          is_active: true
        },
        {
          tag_option_id: '303149000014957359',
          tag_option_name: '400',
          is_active: true
        },
        {
          tag_option_id: '303149000014965081',
          tag_option_name: '401',
          is_active: true
        },
        {
          tag_option_id: '303149000014965083',
          tag_option_name: '402',
          is_active: true
        },
        {
          tag_option_id: '303149000014965085',
          tag_option_name: '403',
          is_active: true
        },
        {
          tag_option_id: '303149000014965087',
          tag_option_name: '404',
          is_active: true
        },
        {
          tag_option_id: '303149000014965089',
          tag_option_name: '405',
          is_active: true
        },
        {
          tag_option_id: '303149000014965091',
          tag_option_name: '406',
          is_active: true
        },
        {
          tag_option_id: '303149000014965093',
          tag_option_name: '407',
          is_active: true
        },
        {
          tag_option_id: '303149000014965095',
          tag_option_name: '408',
          is_active: true
        },
        {
          tag_option_id: '303149000014965097',
          tag_option_name: '409',
          is_active: true
        },
        {
          tag_option_id: '303149000014965099',
          tag_option_name: '410',
          is_active: true
        },
        {
          tag_option_id: '303149000004518061',
          tag_option_name: '411',
          is_active: true
        },
        {
          tag_option_id: '303149000004518063',
          tag_option_name: '412',
          is_active: true
        },
        {
          tag_option_id: '303149000004518065',
          tag_option_name: '413',
          is_active: true
        },
        {
          tag_option_id: '303149000004518067',
          tag_option_name: '414',
          is_active: true
        },
        {
          tag_option_id: '303149000004518069',
          tag_option_name: '415',
          is_active: true
        },
        {
          tag_option_id: '303149000004518071',
          tag_option_name: '416',
          is_active: true
        },
        {
          tag_option_id: '303149000004518073',
          tag_option_name: '417',
          is_active: true
        },
        {
          tag_option_id: '303149000004518075',
          tag_option_name: '418',
          is_active: true
        },
        {
          tag_option_id: '303149000015318593',
          tag_option_name: '419',
          is_active: true
        },
        {
          tag_option_id: '303149000015318595',
          tag_option_name: '420',
          is_active: true
        },
        {
          tag_option_id: '303149000015318597',
          tag_option_name: '421',
          is_active: true
        },
        {
          tag_option_id: '303149000015318599',
          tag_option_name: '422',
          is_active: true
        },
        {
          tag_option_id: '303149000014982883',
          tag_option_name: '423',
          is_active: true
        },
        {
          tag_option_id: '303149000014992137',
          tag_option_name: '424',
          is_active: true
        },
        {
          tag_option_id: '303149000014992139',
          tag_option_name: '425',
          is_active: true
        },
        {
          tag_option_id: '303149000014992141',
          tag_option_name: '426',
          is_active: true
        },
        {
          tag_option_id: '303149000014992143',
          tag_option_name: '427',
          is_active: true
        },
        {
          tag_option_id: '303149000014992145',
          tag_option_name: '428',
          is_active: true
        },
        {
          tag_option_id: '303149000014992147',
          tag_option_name: '429',
          is_active: true
        },
        {
          tag_option_id: '303149000014992149',
          tag_option_name: '430',
          is_active: true
        },
        {
          tag_option_id: '303149000015389147',
          tag_option_name: '431',
          is_active: true
        },
        {
          tag_option_id: '303149000015389149',
          tag_option_name: '432',
          is_active: true
        },
        {
          tag_option_id: '303149000015389151',
          tag_option_name: '433',
          is_active: true
        },
        {
          tag_option_id: '303149000015389153',
          tag_option_name: '434',
          is_active: true
        },
        {
          tag_option_id: '303149000015389155',
          tag_option_name: '435',
          is_active: true
        },
        {
          tag_option_id: '303149000015389157',
          tag_option_name: '436',
          is_active: true
        },
        {
          tag_option_id: '303149000015389159',
          tag_option_name: '437',
          is_active: true
        },
        {
          tag_option_id: '303149000015389161',
          tag_option_name: '438',
          is_active: true
        },
        {
          tag_option_id: '303149000015389163',
          tag_option_name: '439',
          is_active: true
        },
        {
          tag_option_id: '303149000015389165',
          tag_option_name: '440',
          is_active: true
        },
        {
          tag_option_id: '303149000015426059',
          tag_option_name: '441',
          is_active: true
        },
        {
          tag_option_id: '303149000015426061',
          tag_option_name: '442',
          is_active: true
        },
        {
          tag_option_id: '303149000015426063',
          tag_option_name: '443',
          is_active: true
        },
        {
          tag_option_id: '303149000015426065',
          tag_option_name: '444',
          is_active: true
        },
        {
          tag_option_id: '303149000015426067',
          tag_option_name: '445',
          is_active: true
        },
        {
          tag_option_id: '303149000015426069',
          tag_option_name: '446',
          is_active: true
        },
        {
          tag_option_id: '303149000015426071',
          tag_option_name: '447',
          is_active: true
        },
        {
          tag_option_id: '303149000015426073',
          tag_option_name: '448',
          is_active: true
        },
        {
          tag_option_id: '303149000015426075',
          tag_option_name: '449',
          is_active: true
        },
        {
          tag_option_id: '303149000015426077',
          tag_option_name: '450',
          is_active: true
        },
        {
          tag_option_id: '303149000015426079',
          tag_option_name: '451',
          is_active: true
        },
        {
          tag_option_id: '303149000015426081',
          tag_option_name: '452',
          is_active: true
        },
        {
          tag_option_id: '303149000015426083',
          tag_option_name: '453',
          is_active: true
        },
        {
          tag_option_id: '303149000015426085',
          tag_option_name: '454',
          is_active: true
        },
        {
          tag_option_id: '303149000015426087',
          tag_option_name: '455',
          is_active: true
        }
      ]
    },
    {
      tag_id: '303149000000000333',
      tag_name: 'Vendor Invoice Details',
      index: 1,
      associated_with: 'item',
      associated_with_formatted: 'Item',
      is_active: true,
      is_tag_mandatory: false,
      tag_options: [
        {
          tag_option_id: '303149000000278085',
          tag_option_name: 'Not received',
          is_active: true
        },
        {
          tag_option_id: '303149000000278073',
          tag_option_name: 'Received in Full',
          is_active: true
        },
        {
          tag_option_id: '303149000000278083',
          tag_option_name: 'Received in Partial',
          is_active: true
        }
      ]
    }
  ],
  org_settings: {
    organization_id: '581594806',
    name: 'Haystack Marketing Services Pvt. Ltd',
    date_format: 'dd/MM/yyyy',
    field_seperator: '/',
    time_zone: 'Asia/Calcutta',
    financial_year_start_day: 1,
    financial_year_start_month: '',
    user_status: '',
    tax_group_enabled: true,
    price_precision: 2,
    currency_id: '303149000000000099',
    currency_code: 'INR',
    currency_symbol: 'Rs.',
    js_date_format: '%d/%m/%Y'
  },
  contact: {
    contact_id: '303149000009402280',
    name: 'GaneshTestingVendor',
    contact_type: 2,
    currency_id: '303149000000000099',
    place_of_contact: 'KA',
    currency_code: 'INR',
    currency_symbol: 'Rs.',
    currency_formatter: {
      decimal_separator: '.',
      number_separator: ','
    },
    payment_terms: 30,
    payment_terms_label: 'Net 30',
    price_precision: 2,
    tax_treatment: 'business_gst',
    tax_treatment_formatted: 'Registered Business - Regular',
    tds_tax_id: '',
    tags: [],
    is_tds_expired: '',
    is_tax_expired: '',
    gst_no: '29AACCH9352H1ZA',
    contact_category: 'business_gst',
    contact_category_formatted: 'Registered Business - Regular',
    gst_treatment: 'business_gst',
    gst_treatment_formatted: 'Registered Business - Regular',
    bill_template_id: '',
    billing_address: {
      address: '',
      phone: ''
    }
  },
  payment_terms: [
    {
      payment_terms_id: '303149000000251258',
      payment_terms: 7,
      is_default: false,
      payment_terms_label: 'Net 7'
    },
    {
      payment_terms_id: '303149000000000137',
      payment_terms: 15,
      is_default: false,
      payment_terms_label: 'Net 15'
    },
    {
      payment_terms_id: '303149000017235373',
      payment_terms: 20,
      is_default: false,
      payment_terms_label: 'Net 20'
    },
    {
      payment_terms_id: '303149000000000139',
      payment_terms: 30,
      is_default: false,
      payment_terms_label: 'Net 30'
    },
    {
      payment_terms_id: '303149000000000141',
      payment_terms: 45,
      is_default: false,
      payment_terms_label: 'Net 45'
    },
    {
      payment_terms_id: '303149000000000143',
      payment_terms: 60,
      is_default: false,
      payment_terms_label: 'Net 60'
    },
    {
      payment_terms_id: '303149000030096481',
      payment_terms: 90,
      is_default: false,
      payment_terms_label: '90'
    },
    {
      payment_terms_id: '',
      is_mandatory: true,
      payment_terms: -2,
      payment_terms_label: 'Due end of the month'
    },
    {
      payment_terms_id: '',
      is_mandatory: true,
      payment_terms: -3,
      payment_terms_label: 'Due end of next month'
    }
  ],
  item_custom_fields: [],
  templates: [
    {
      template_name: 'Standard Template',
      template_id: '303149000000031017',
      template_type: 'standard'
    }
  ],
  gst_treatments: [
    {
      code: 4,
      allowed_for_purchase: true,
      value_formatted: 'Registered Business - Regular',
      description: 'Business that is registered under GST',
      label: 'Registered Business - Regular',
      category: 'business',
      value: 'business_gst',
      allowed_for_sales: true
    },
    {
      code: 12,
      allowed_for_purchase: true,
      value_formatted: 'Registered Business - Composition',
      description:
        'Business that is registered under the Composition Scheme in GST',
      label: 'Registered Business - Composition',
      category: 'business',
      value: 'business_registered_composition',
      allowed_for_sales: true
    },
    {
      code: -1,
      allowed_for_purchase: true,
      value_formatted: 'Unregistered Business',
      description: 'Business that has not been registered under GST',
      label: 'Unregistered Business',
      category: 'business',
      value: 'business_none',
      allowed_for_sales: true
    },
    {
      code: 8,
      allowed_for_purchase: false,
      value_formatted: 'Consumer',
      description: 'A customer who is a regular consumer',
      label: 'Consumer',
      category: 'consumer',
      value: 'consumer',
      allowed_for_sales: true
    },
    {
      code: 7,
      allowed_for_purchase: true,
      value_formatted: 'Overseas',
      description:
        'Persons with whom you do import or export of supplies outside India',
      label: 'Overseas',
      category: 'consumer',
      value: 'overseas',
      allowed_for_sales: true
    },
    {
      code: 10,
      allowed_for_purchase: true,
      value_formatted: 'Special Economic Zone',
      description:
        'Business (Unit) that is located in a Special Economic Zone (SEZ) of India or a SEZ Developer',
      label: 'Special Economic Zone',
      category: 'business',
      value: 'business_sez',
      allowed_for_sales: true
    },
    {
      code: 13,
      allowed_for_purchase: true,
      value_formatted: 'Deemed Export',
      description:
        'Supply of goods to an Export Oriented Unit  or against Advanced Authorization/Export Promotion Capital Goods.',
      label: 'Deemed Export',
      category: 'business',
      value: 'deemed_export',
      allowed_for_sales: true
    },
    {
      code: 9,
      allowed_for_purchase: true,
      value_formatted: 'Non-GST Supply',
      description: 'Transactions with supplies that do not attract GST',
      label: 'Non-GST Supply',
      category: 'consumer',
      value: 'non_gst_supply',
      allowed_for_sales: true
    },
    {
      code: 11,
      allowed_for_purchase: true,
      value_formatted: 'Out Of Scope',
      description: 'Transactions that do not come under the ambit of GST',
      label: 'Out Of Scope',
      category: 'consumer',
      value: 'out_of_scope',
      allowed_for_sales: true
    },
    {
      code: 14,
      allowed_for_purchase: true,
      value_formatted: 'Tax Deductor',
      description:
        'Departments of the State/Central government, governmental agencies or local authorities',
      label: 'Tax Deductor',
      category: 'business',
      value: 'tax_deductor',
      allowed_for_sales: true
    },
    {
      code: 15,
      allowed_for_purchase: true,
      value_formatted: 'SEZ Developer',
      description:
        'A person/organisation who owns at least 26% of the equity in creating business units in a Special Economic Zone (SEZ)',
      label: 'SEZ Developer',
      category: 'business',
      value: 'sez_developer',
      allowed_for_sales: true
    }
  ],
  tax_treatments: [
    {
      code: 4,
      allowed_for_purchase: true,
      value_formatted: 'Registered Business - Regular',
      description: 'Business that is registered under GST',
      label: 'Registered Business - Regular',
      category: 'business',
      value: 'business_gst',
      allowed_for_sales: true
    },
    {
      code: 12,
      allowed_for_purchase: true,
      value_formatted: 'Registered Business - Composition',
      description:
        'Business that is registered under the Composition Scheme in GST',
      label: 'Registered Business - Composition',
      category: 'business',
      value: 'business_registered_composition',
      allowed_for_sales: true
    },
    {
      code: -1,
      allowed_for_purchase: true,
      value_formatted: 'Unregistered Business',
      description: 'Business that has not been registered under GST',
      label: 'Unregistered Business',
      category: 'business',
      value: 'business_none',
      allowed_for_sales: true
    },
    {
      code: 8,
      allowed_for_purchase: false,
      value_formatted: 'Consumer',
      description: 'A customer who is a regular consumer',
      label: 'Consumer',
      category: 'consumer',
      value: 'consumer',
      allowed_for_sales: true
    },
    {
      code: 7,
      allowed_for_purchase: true,
      value_formatted: 'Overseas',
      description:
        'Persons with whom you do import or export of supplies outside India',
      label: 'Overseas',
      category: 'consumer',
      value: 'overseas',
      allowed_for_sales: true
    },
    {
      code: 10,
      allowed_for_purchase: true,
      value_formatted: 'Special Economic Zone',
      description:
        'Business (Unit) that is located in a Special Economic Zone (SEZ) of India or a SEZ Developer',
      label: 'Special Economic Zone',
      category: 'business',
      value: 'business_sez',
      allowed_for_sales: true
    },
    {
      code: 13,
      allowed_for_purchase: true,
      value_formatted: 'Deemed Export',
      description:
        'Supply of goods to an Export Oriented Unit  or against Advanced Authorization/Export Promotion Capital Goods.',
      label: 'Deemed Export',
      category: 'business',
      value: 'deemed_export',
      allowed_for_sales: true
    },
    {
      code: 9,
      allowed_for_purchase: true,
      value_formatted: 'Non-GST Supply',
      description: 'Transactions with supplies that do not attract GST',
      label: 'Non-GST Supply',
      category: 'consumer',
      value: 'non_gst_supply',
      allowed_for_sales: true
    },
    {
      code: 11,
      allowed_for_purchase: true,
      value_formatted: 'Out Of Scope',
      description: 'Transactions that do not come under the ambit of GST',
      label: 'Out Of Scope',
      category: 'consumer',
      value: 'out_of_scope',
      allowed_for_sales: true
    },
    {
      code: 14,
      allowed_for_purchase: true,
      value_formatted: 'Tax Deductor',
      description:
        'Departments of the State/Central government, governmental agencies or local authorities',
      label: 'Tax Deductor',
      category: 'business',
      value: 'tax_deductor',
      allowed_for_sales: true
    },
    {
      code: 15,
      allowed_for_purchase: true,
      value_formatted: 'SEZ Developer',
      description:
        'A person/organisation who owns at least 26% of the equity in creating business units in a Special Economic Zone (SEZ)',
      label: 'SEZ Developer',
      category: 'business',
      value: 'sez_developer',
      allowed_for_sales: true
    }
  ]
};

export const states = [
  {
    id: 'AN',
    text: 'Andaman and Nicobar Islands',
    search_text: 'Andaman and Nicobar Islands'
  },
  {
    id: 'AD',
    text: 'Andhra Pradesh',
    search_text: 'Andhra Pradesh'
  },
  {
    id: 'AR',
    text: 'Arunachal Pradesh',
    search_text: 'Arunachal Pradesh'
  },
  {
    id: 'AS',
    text: 'Assam',
    search_text: 'Assam'
  },
  {
    id: 'BR',
    text: 'Bihar',
    search_text: 'Bihar'
  },
  {
    id: 'CH',
    text: 'Chandigarh',
    search_text: 'Chandigarh'
  },
  {
    id: 'CG',
    text: 'Chhattisgarh',
    search_text: 'Chhattisgarh'
  },
  {
    id: 'DN',
    text: 'Dadra and Nagar Haveli and Daman and Diu',
    search_text: 'Dadra and Nagar Haveli and Daman and Diu'
  },
  {
    id: 'DD',
    text: 'Daman and Diu',
    search_text: 'Daman and Diu'
  },
  {
    id: 'DL',
    text: 'Delhi',
    search_text: 'Delhi'
  },
  {
    id: 'FC',
    text: 'Foreign Country',
    search_text: 'Foreign Country'
  },
  {
    id: 'GA',
    text: 'Goa',
    search_text: 'Goa'
  },
  {
    id: 'GJ',
    text: 'Gujarat',
    search_text: 'Gujarat'
  },
  {
    id: 'HR',
    text: 'Haryana',
    search_text: 'Haryana'
  },
  {
    id: 'HP',
    text: 'Himachal Pradesh',
    search_text: 'Himachal Pradesh'
  },
  {
    id: 'JK',
    text: 'Jammu and Kashmir',
    search_text: 'Jammu and Kashmir'
  },
  {
    id: 'JH',
    text: 'Jharkhand',
    search_text: 'Jharkhand'
  },
  {
    id: 'KA',
    text: 'Karnataka',
    search_text: 'Karnataka'
  },
  {
    id: 'KL',
    text: 'Kerala',
    search_text: 'Kerala'
  },
  {
    id: 'LA',
    text: 'Ladakh',
    search_text: 'Ladakh'
  },
  {
    id: 'LD',
    text: 'Lakshadweep',
    search_text: 'Lakshadweep'
  },
  {
    id: 'MP',
    text: 'Madhya Pradesh',
    search_text: 'Madhya Pradesh'
  },
  {
    id: 'MH',
    text: 'Maharashtra',
    search_text: 'Maharashtra'
  },
  {
    id: 'MN',
    text: 'Manipur',
    search_text: 'Manipur'
  },
  {
    id: 'ML',
    text: 'Meghalaya',
    search_text: 'Meghalaya'
  },
  {
    id: 'MZ',
    text: 'Mizoram',
    search_text: 'Mizoram'
  },
  {
    id: 'NL',
    text: 'Nagaland',
    search_text: 'Nagaland'
  },
  {
    id: 'OD',
    text: 'Odisha',
    search_text: 'Odisha'
  },
  {
    id: 'OT',
    text: 'Other Territory',
    search_text: 'Other Territory'
  },
  {
    id: 'PY',
    text: 'Puducherry',
    search_text: 'Puducherry'
  },
  {
    id: 'PB',
    text: 'Punjab',
    search_text: 'Punjab'
  },
  {
    id: 'RJ',
    text: 'Rajasthan',
    search_text: 'Rajasthan'
  },
  {
    id: 'SK',
    text: 'Sikkim',
    search_text: 'Sikkim'
  },
  {
    id: 'TN',
    text: 'Tamil Nadu',
    search_text: 'Tamil Nadu'
  },
  {
    id: 'TS',
    text: 'Telangana',
    search_text: 'Telangana'
  },
  {
    id: 'TR',
    text: 'Tripura',
    search_text: 'Tripura'
  },
  {
    id: 'UP',
    text: 'Uttar Pradesh',
    search_text: 'Uttar Pradesh'
  },
  {
    id: 'UK',
    text: 'Uttarakhand',
    search_text: 'Uttarakhand'
  },
  {
    id: 'WB',
    text: 'West Bengal',
    search_text: 'West Bengal'
  }
];

export const gst_treatment = [
  {
    code: 4,
    allowed_for_purchase: true,
    value_formatted: 'Registered Business - Regular',
    description: 'Business that is registered under GST',
    label: 'Registered Business - Regular',
    category: 'business',
    value: 'business_gst',
    allowed_for_sales: true
  },
  {
    code: 12,
    allowed_for_purchase: true,
    value_formatted: 'Registered Business - Composition',
    description:
      'Business that is registered under the Composition Scheme in GST',
    label: 'Registered Business - Composition',
    category: 'business',
    value: 'business_registered_composition',
    allowed_for_sales: true
  },
  {
    code: -1,
    allowed_for_purchase: true,
    value_formatted: 'Unregistered Business',
    description: 'Business that has not been registered under GST',
    label: 'Unregistered Business',
    category: 'business',
    value: 'business_none',
    allowed_for_sales: true
  },
  {
    code: 8,
    allowed_for_purchase: false,
    value_formatted: 'Consumer',
    description: 'A customer who is a regular consumer',
    label: 'Consumer',
    category: 'consumer',
    value: 'consumer',
    allowed_for_sales: true
  },
  {
    code: 7,
    allowed_for_purchase: true,
    value_formatted: 'Overseas',
    description:
      'Persons with whom you do import or export of supplies outside India',
    label: 'Overseas',
    category: 'consumer',
    value: 'overseas',
    allowed_for_sales: true
  },
  {
    code: 10,
    allowed_for_purchase: true,
    value_formatted: 'Special Economic Zone',
    description:
      'Business (Unit) that is located in a Special Economic Zone (SEZ) of India or a SEZ Developer',
    label: 'Special Economic Zone',
    category: 'business',
    value: 'business_sez',
    allowed_for_sales: true
  },
  {
    code: 13,
    allowed_for_purchase: true,
    value_formatted: 'Deemed Export',
    description:
      'Supply of goods to an Export Oriented Unit  or against Advanced Authorization/Export Promotion Capital Goods.',
    label: 'Deemed Export',
    category: 'business',
    value: 'deemed_export',
    allowed_for_sales: true
  },
  {
    code: 9,
    allowed_for_purchase: true,
    value_formatted: 'Non-GST Supply',
    description: 'Transactions with supplies that do not attract GST',
    label: 'Non-GST Supply',
    category: 'consumer',
    value: 'non_gst_supply',
    allowed_for_sales: true
  },
  {
    code: 11,
    allowed_for_purchase: true,
    value_formatted: 'Out Of Scope',
    description: 'Transactions that do not come under the ambit of GST',
    label: 'Out Of Scope',
    category: 'consumer',
    value: 'out_of_scope',
    allowed_for_sales: true
  },
  {
    code: 14,
    allowed_for_purchase: true,
    value_formatted: 'Tax Deductor',
    description:
      'Departments of the State/Central government, governmental agencies or local authorities',
    label: 'Tax Deductor',
    category: 'business',
    value: 'tax_deductor',
    allowed_for_sales: true
  },
  {
    code: 15,
    allowed_for_purchase: true,
    value_formatted: 'SEZ Developer',
    description:
      'A person/organisation who owns at least 26% of the equity in creating business units in a Special Economic Zone (SEZ)',
    label: 'SEZ Developer',
    category: 'business',
    value: 'sez_developer',
    allowed_for_sales: true
  }
];
