import React, { useState, useRef, useEffect } from 'react';
import { IconButton, TextField } from '@mui/material';
import { Close, Magnify } from 'mdi-material-ui';

interface ISearchFilter {
  onInputChange: (value: string) => void;
  defaultValue: string;
  placeholder?: any
}
let debounce: unknown;

function SearchFilter({ onInputChange, defaultValue, placeholder = 'Search' }: ISearchFilter) {
  const [inputValue, setInputValue] = useState(defaultValue || '');
  const clearField = () => {
    onInputChange('');
    setInputValue('');
  };
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <TextField
      variant="outlined"
      placeholder={placeholder}
      size="small"
      inputRef={inputRef}
      value={inputValue}
      onChange={(e) => {
        setInputValue(e.target.value);
        if (debounce) clearTimeout(debounce as string);
        debounce = setTimeout(() => {
          onInputChange(e.target.value);
        }, 500);
      }}
      InputProps={{
        endAdornment: (
          <>
            {inputValue.trim() === '' ? (
              <Magnify
                sx={{ width: '16px', height: '16px', color: '#6B778C' }}
              />
            ) : (
              <IconButton onClick={clearField}>
                <Close
                  sx={{ width: '16px', height: '16px', color: '#6B778C' }}
                />
              </IconButton>
            )}
          </>
        )
      }}
    />
  );
}

export default SearchFilter;
