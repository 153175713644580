import React, { Suspense } from 'react';
import { createBrowserRouter, Outlet } from 'react-router-dom';

import Main from './Main';

import Login from './Authentication';
//import Adex from './Adex';
import Home from './home';
import App from './App';
import Campaign from './Campaign';
import RequestAnBR from './RequestAnBR';
import Plans from './PlanRequests';
import CampaignAnalytics from './CampaignAnalytics';
import Companies from './Companies';
import Uploads from './Uploads';
import Roles from './Roles';
import Brand from './Brands';
import Ads from './Ads';
import Users from './Users';
import FAQ from './FAQ';
import Revenue from './Revenue';
import MarketingCalculator from './MarketingCalculators';
import EmailTemplate from './EmailTemplate';
import MediaAgency from './MediaAgency';
import PurchaseOrder from './PurchaseOrders';
import FinanceTasks from './TaskManagementSystem/FinanceTeam';
import AMTeam from './TaskManagementSystem/AMTeam';
import IndustryLandingPage from './IndustryLandingPage';
import MarketingTool from './MarketingTool'
import RevenueModule from './deals/RevenueTable';

export const childRoutes = [
  // {
  //   ...Home
  // },
  /*	{
    ...Adex
  }, */
  {
    ...Campaign
  },
  {
    ...Revenue
  },
  {
    ...PurchaseOrder
  },
  // {
  //   ...Tasks
  // },
  {
    ...FinanceTasks
  },
  {
    ...AMTeam
  },
  {
    ...CampaignAnalytics
  },
  {
    ...Plans
  },
  // {
  //   ...RequestAnBR
  // },
  {
    ...MarketingCalculator
  },
  {
    ...Companies
  },
  {
    ...Brand
  },
  {
    ...Ads
  },
  {
    ...EmailTemplate
  },
  {
    ...MediaAgency
  },
  {
    ...Uploads
  },
  {
    ...Users
  },
  {
    ...FAQ
  },
  {
    ...Roles
  },
  {
    ...IndustryLandingPage
  },
  {
    ...MarketingTool
  },
  {
    ...RevenueModule
  }
];

const router = createBrowserRouter([
  {
    element: <Main />,
    children: [
      {
        path: '/login',
        element: <Login />
      },
      {
        element: <App />,
        children: childRoutes
      }
    ]
  }
]);

export default router;
