import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography
} from '@mui/material';
import { Pencil } from 'mdi-material-ui';
import { useState } from 'react';
import { ITaxInfoList } from '../interface';

interface IGSTChangesProps {
  taxInfoList: ITaxInfoList[];
  currentGST: string;
  onGSTChange: (gst: ITaxInfoList | null) => void;
}

const GSTChange = ({
  taxInfoList,
  currentGST,
  onGSTChange
}: IGSTChangesProps) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [gst, setGst] = useState<string>(currentGST);
  return (
    <>
      {!edit ? (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography>{currentGST}</Typography>
          <IconButton onClick={() => setEdit(!edit)}>
            <Pencil sx={{ fontSize: '18px', color: 'primary.main' }} />
          </IconButton>
        </Stack>
      ) : (
        <Box>
          <Select
            value={gst}
            onChange={(e: SelectChangeEvent) => setGst(e.target.value)}
          >
            {taxInfoList.map((item: ITaxInfoList) => (
              <MenuItem value={item?.tax_registration_no} key={item?._id}>
                {item?.tax_registration_no}
              </MenuItem>
            ))}
          </Select>
          <Stack direction="row" alignItems="center" spacing={2} mt={2}>
            <Button
              variant="outlined"
              sx={{ fontWeight: 400, borderRadius: '4px', padding: '6px 14px' }}
              onClick={() => {
                setEdit(false);
                setGst(currentGST);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ fontWeight: 400, borderRadius: '4px', padding: '6px 14px' }}
              onClick={() => {
                onGSTChange(
                  taxInfoList?.find(
                    (item: ITaxInfoList) => item?.tax_registration_no === gst
                  ) || null
                );
                setEdit(false);
              }}
            >
              Submit
            </Button>
          </Stack>
        </Box>
      )}
    </>
  );
};

export default GSTChange;
