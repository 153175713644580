import { IconButton, Stack, TextField, Typography } from '@mui/material';
import { Check, SquareEditOutline } from 'mdi-material-ui';
import { useState } from 'react';

interface IRate {
  defaultRate: number;
  onRateChange: (rate: number) => void;
  disabled: boolean;
}

const Rate = ({ defaultRate, onRateChange, disabled }: IRate) => {
  const [rate, setRate] = useState(defaultRate);
  const [editRate, setEditRate] = useState<boolean>(false);
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={2}
    >
      {editRate ? (
        <TextField
          type="number"
          value={rate}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setRate(Number(event.target.value))
          }
        />
      ) : (
        <Typography>{rate}</Typography>
      )}
      <IconButton
        color="primary"
        disabled={disabled}
        onClick={() => {
          setEditRate(!editRate);
          onRateChange(rate);
        }}
      >
        {editRate ? <Check /> : <SquareEditOutline />}
      </IconButton>
    </Stack>
  );
};

export default Rate;
