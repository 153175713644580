import { SingleFileUploadDragDrop } from '@media-ant/ui';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import {
  Close,
  DeleteOutline,
  InformationVariantCircleOutline
} from 'mdi-material-ui';
import { ChangeEvent, useState } from 'react';
import { IBillFiles, IFile } from '../interface';
import BillCard from './BillCard';
import { useAppDispatch } from '../../hooks';
import { billUpload, rejectBill } from '../redux/actionCreator';
import { useSelector } from 'react-redux';

interface IBillUploadDialogProps {
  open: boolean;
  onClose: () => void;
  bills: IBillFiles[];
  poId: string;
  poNumber: string;
}

const BillsUploadDialog = ({
  open,
  onClose,
  bills,
  poId,
  poNumber
}: IBillUploadDialogProps) => {
  const dispatch = useAppDispatch();
  const [files, setFiles] = useState<IFile[]>([]);
  const [isUploadingFiles, setIsUploadingFiles] = useState<boolean>(false);
  const [isRejecting, setIsRejecting] = useState<string | null>(null);
  const [billingValue, setBillingValue] = useState('');
  const [poBills, setPOBills] = useState<IBillFiles[]>(bills);
  const { user } = useSelector((state: any) => state.session);
  const handleFileUpload = (value: any) => {
    setFiles((prevState) => {
      return [...prevState, value];
    });
  };

  const handleFileDelete = (idx: number) => {
    setFiles((prevState) => {
      let filteredState = [...prevState];
      filteredState.splice(idx, 1);
      return filteredState;
    });
  };

  const uploadBills = () => {
    setIsUploadingFiles(true);
    const formData = new FormData();
    files.forEach((file: any) => {
      formData.append('files', file);
    });
    formData.append('billValue', billingValue);
    dispatch(billUpload({ zohoPoId: poId || '', formData, user })).then(
      (res: any) => {
        if (!res.error) {
          setFiles([]);
          const { bills } = res.payload?.response;
          let tempBills = new Set(poBills.map((bill: IBillFiles) => bill._id));
          bills.forEach((bill: IBillFiles) => {
            if (!tempBills.has(bill._id)) {
              bill = { ...bill, _user: user };
              setPOBills((prevState: any) => [...prevState, bill]);
            }
          });
        }
        setIsUploadingFiles(false);
      }
    );
  };

  const handleRejectBill = (poId: string, billId: string, comment: string) => {
    setIsRejecting(billId);
    dispatch(rejectBill({ poId, billId, comment })).then((res: any) => {
      setIsRejecting(null);
      let tempBills: IBillFiles[] = JSON.parse(JSON.stringify(poBills));
      tempBills.forEach((bill: IBillFiles, index: number) => {
        if (bill?._id === billId) {
          tempBills[index] = {
            ...tempBills[index],
            rejected: true,
            rejectedReason: comment
          };
        }
      });
      setPOBills(tempBills);
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      PaperProps={{
        sx: {
          width: '750px',
          maxWidth: '750px'
        }
      }}
    >
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography>Bills Upload</Typography>
          <Typography color="primary" fontWeight={600}>
            {poNumber}
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <Stack direction="row" alignItems="center" spacing={4} p={2}>
          <Typography>Billing Amount : </Typography>
          <TextField
            size="small"
            type="number"
            value={billingValue}
            InputProps={{ inputProps: { min: 0 } }}
            placeholder="Enter the billing value"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setBillingValue(e.target.value)
            }
          />
        </Stack>
        <Divider sx={{ m: '10px' }} />
        <SingleFileUploadDragDrop
          maxFileSize="1.5 GB"
          onChange={handleFileUpload}
          isDisabled={Number(billingValue) === 0 || files.length === 1}
        />
        {Number(billingValue) === 0 && (
          <Stack direction="row" alignItems="center" spacing={1} m={'10px 0px'}>
            <InformationVariantCircleOutline
              color="error"
              sx={{ fontSize: '16px' }}
            />
            <Typography variant="caption" color="error">
              Please Add billing value to be able to upload file
            </Typography>
          </Stack>
        )}
        <Divider sx={{ m: '10px' }} />
        {files.length > 0 && (
          <>
            <Typography>Files</Typography>
            <Stack m={'10px 0px'}>
              {files?.map((file: IFile, index: number) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  p={'0px 10px'}
                  sx={{ backgroundColor: '#e3e3e3', borderRadius: '4px' }}
                  key={index}
                >
                  <Tooltip title={file.name}>
                    <Typography
                      variant="h5"
                      noWrap
                      sx={{
                        maxWidth: '300px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                      }}
                    >
                      {file.name}
                    </Typography>
                  </Tooltip>
                  <IconButton
                    color="error"
                    onClick={() => handleFileDelete(index)}
                  >
                    <DeleteOutline />
                  </IconButton>
                </Stack>
              ))}
            </Stack>
            <Button
              variant="contained"
              onClick={() => uploadBills()}
              sx={{
                padding: '6px 22px',
                fontSize: '14px',
                borderRadius: '4px',
                fontWeight: 500
              }}
              disabled={isUploadingFiles}
              endIcon={isUploadingFiles && <CircularProgress size={18} />}
            >
              Submit
            </Button>
          </>
        )}
        {poBills.length > 0 && (
          <>
            <Divider sx={{ m: '10px' }} />
            <Typography>Bills</Typography>
            {poBills?.map((bill: IBillFiles, index: number) => (
              <BillCard
                bill={bill}
                poId={poId}
                index={index}
                handleRejectBill={handleRejectBill}
                isRejecting={isRejecting}
              />
            ))}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default BillsUploadDialog;
