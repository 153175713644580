"use client"

import type React from "react"
import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, CircularProgress, Tooltip, TableFooter } from "@mui/material"
import { Link } from "react-router-dom";
const apiHost = process.env.REACT_APP_API_HOST;

const DealPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [isFetcing, setIsFetching] = useState(true);
  const [dealData, setDealData] = useState<any>({ campaigns: [] })

  useEffect(() => {
    const fetchDealData = async () => {
      setIsFetching(true);
      // Replace this with your actual API call
      const response = await fetch(
        `${apiHost}/deals/${id}/margins`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'x-access-token': localStorage.authToken
        }
      }
      );
      const data = await response.json();
      if (data && data.fsDealId && data.fsDealId.toString() === id) {
        setIsFetching(false);
        setDealData(data)
      }
    }

    fetchDealData();
  }, [id])

  if (isFetcing) {
    return <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        zIndex: 2,
      }}
    >
      <CircularProgress />
    </Box>
  }

  return (
    <div style={{ margin: "30px 20px 20px" }}>
      <Typography variant="h3" sx={{ mb: '10px' }}>
        Deal: <span style={{ color: '#2525d2' }}>{dealData.dealName}</span>
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="campaigns table">
          <TableHead>
            <TableRow>
              <TableCell>Campaign Name</TableCell>
              <TableCell>Payable</TableCell>
              <TableCell>Receivable</TableCell>
              <TableCell>Profit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dealData.campaigns.map((campaign: any) => (
              <TableRow key={campaign._id}>
                <TableCell component="th" scope="row">
                  <Link to={`/campaigns/${campaign._id}/plan-schedule`} style={{ textDecoration: "none" }}>
                    {campaign.name}
                  </Link>
                </TableCell>
                <TableCell>&#8377;{campaign.payable.toFixed(2)}</TableCell>
                <TableCell>&#8377;{campaign.receivable.toFixed(2)}</TableCell>
                <TableCell sx={{
                  color: campaign.receivable - campaign.payable > 0 ? "green" : "red",
                  fontWeight: "bold",
                }}>
                  <Tooltip title={`${Math.abs((campaign.receivable - campaign.payable)).toFixed(2)}`}>
                    <span>
                      {campaign.receivable
                        ? ((campaign.receivable - campaign.payable) / campaign.receivable * 100).toFixed(2) + "%"
                        : "0%"}
                    </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell component="th" scope="row">
                Total
              </TableCell>
              <TableCell><b>&#8377;{dealData.payable.toFixed(2)}</b></TableCell>
              <TableCell><b>&#8377;{dealData.receivable.toFixed(2)}</b></TableCell>
              <TableCell sx={{
                color: dealData.receivable - dealData.payable > 0 ? "green" : "red",
                fontWeight: "bold",
              }}
              >
                <Tooltip title={`${Math.abs((dealData.receivable - dealData.payable)).toFixed(2)}`}>
                  <span>
                    <b>
                      {dealData.receivable
                        ? ((dealData.receivable - dealData.payable) / dealData.receivable * 100).toFixed(2) + "%"
                        : "0%"}
                    </b>
                  </span>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default DealPage

