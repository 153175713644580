import React from 'react';
import {
  Select,
  Stack,
  Typography,
  Grid,
  MenuItem,
  SelectChangeEvent,
  Tooltip
} from '@mui/material';
import { ITDSTax } from '../interface';

interface IPriceCalculatorFormProps {
  subTotal: number;
  discount: number;
  taxTotal: number;
  tds: number;
  total: number;
  taxes: ITDSTax[];
  tdsTaxId: string;
  onTDSChange: (tsd_id: string) => void;
}

// Reusable Row Component (to reduce repetition)
const Row = ({
  label,
  value,
  tooltip = false
}: {
  label: string;
  value: string | number;
  tooltip?: boolean;
}) => (
  <>
    <Grid item xs={4}>
      <Typography variant="h4">{label}</Typography>
    </Grid>
    <Grid item xs={8}>
      {tooltip ? (
        <Tooltip title={value}>
          <Typography variant="body1">{value}</Typography>
        </Tooltip>
      ) : (
        <Typography variant="body1">{value}</Typography>
      )}
    </Grid>
  </>
);

const PriceCalculatorForm: React.FC<IPriceCalculatorFormProps> = ({
  subTotal,
  discount,
  taxTotal,
  tds,
  total,
  taxes,
  tdsTaxId,
  onTDSChange
}) => {
  return (
    <Grid
      container
      spacing={4}
      p={4}
      m={'10px 0px'}
      sx={{ backgroundColor: '#f9f9fb', borderRadius: '8px' }}
    >
      <Row label="Sub Total" value={subTotal} />
      <Row label="Discount" value={discount} tooltip />
      <Row label="Taxes" value={taxTotal?.toFixed(2)} tooltip />
      <Grid item xs={4}>
        <Typography variant="h4">TDS</Typography>
      </Grid>
      <Grid item xs={8}>
        <Stack direction="row" alignItems="center" spacing={6}>
          <Select
            size="small"
            sx={{ minWidth: '300px' }}
            value={tdsTaxId}
            onChange={(e: SelectChangeEvent) => onTDSChange(e.target.value)}
          >
            {taxes.map((item) => (
              <MenuItem value={item.tax_id} key={item.tax_id}>
                {`${item.tax_name} - (${item.tax_percentage}%)`}
              </MenuItem>
            ))}
          </Select>
          <Typography variant="body1">{tds}</Typography>
        </Stack>
      </Grid>

      <Row label="Total Tax Amount" value={(taxTotal + tds)?.toFixed(2)} />
      <Row label="Total" value={total?.toFixed(4)} />
    </Grid>
  );
};

export default PriceCalculatorForm;
