import { Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import DataGrid, { Column } from 'react-data-grid';
import '../styles/styles.css';
import { ILineItems, ITax } from '../../interface';
import { DeleteOutline } from 'mdi-material-ui';
import Quantity from './Quantity';
import Taxes from './Taxes';
import Rate from './Rate';
import { useState, useEffect } from 'react';

interface IItemsTableProps {
  lineItems: any;
  onDeleteRow: (index: number) => void;
  onRowsChange: (lines: ILineItems[]) => void;
  disabled: boolean;
  destination_of_supply: string;
  source_of_supply: string;
}

const ItemsTable = ({
  lineItems,
  onDeleteRow,
  onRowsChange,
  disabled,
  destination_of_supply,
  source_of_supply
}: IItemsTableProps) => {
  const [rows, setRows] = useState<ILineItems[]>(lineItems);

  useEffect(() => {
    setRows(lineItems);
  }, [lineItems]);

  const rowHeight = 60;
  const maxVisibleRows = 5;
  const totalHeight = Math.min(rows.length, maxVisibleRows) * rowHeight + 56;

  useEffect(() => {
    setRows(lineItems);
  }, [lineItems]);

  const columns: Column<ILineItems>[] = [
    {
      key: 'details',
      name: 'Item Details',
      width: 300,
      headerCellClass: 'custom-header',
      renderCell: ({ row }) => (
        <Tooltip title={row?.description}>
          <Typography
            noWrap
            sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
          >
            {row?.description || 'N/A'}
          </Typography>
        </Tooltip>
      )
    },
    {
      key: 'account',
      name: 'Account',
      headerCellClass: 'custom-header',
      renderCell: ({ row }) => (
        <Typography>{row?.account_name || 'N/A'}</Typography>
      )
    },
    {
      key: 'quantity',
      name: 'Quantity',
      headerCellClass: 'custom-header',
      renderCell: ({ row, column: { key }, onRowChange }) => (
        <Quantity
          defaultQuantity={row?.quantity}
          disabled={disabled}
          onQuantityChange={(quantity: number) => {
            const updatedRow = {
              ...row,
              [key]: quantity,
              item_total: quantity * row?.rate
            };
            onRowChange(updatedRow);
          }}
        />
      )
    },
    {
      key: 'rate',
      name: 'Rate',
      headerCellClass: 'custom-header',
      renderCell: ({ row, column: { key }, onRowChange }) => (
        <Rate
          defaultRate={row?.rate}
          disabled={disabled}
          onRateChange={(rate: number) => {
            const updatedRow = {
              ...row,
              [key]: rate,
              item_total: row?.quantity * rate
            };
            onRowChange(updatedRow);
          }}
        />
      )
    },
    {
      key: 'tax',
      name: 'Tax',
      minWidth: 250,
      headerCellClass: 'custom-header',
      renderCell: ({ row, column: { key }, onRowChange }) => (
        <Taxes
          defaultTax={row?.tax_name}
          disabled={disabled}
          destination_of_supply={destination_of_supply}
          source_of_supply={source_of_supply}
          onChangeTax={(tax: ITax) => {
            const updatedRow = {
              ...row,
              tax_id: tax?.tax_id,
              tax_name: tax?.tax_name,
              tax_percentage: tax?.tax_percentage
            };
            onRowChange(updatedRow);
          }}
        />
      )
    },
    {
      key: 'amount',
      name: 'Amount',
      headerCellClass: 'custom-header',
      renderCell: ({ row }) => (
        <Typography sx={{ fontWeight: 'bold', color: '#1976d2' }}>
          ₹ {row?.item_total?.toFixed(2)}
        </Typography>
      )
    },
    {
      key: 'action',
      name: 'Action',
      headerCellClass: 'custom-header',
      renderCell: ({ row }) => (
        <Tooltip title="Delete Item">
          <IconButton
            color="error"
            disabled={lineItems.length < 2}
            onClick={() =>
              onDeleteRow(
                lineItems.findIndex((item: any) => item.id === row.id)
              )
            }
          >
            <DeleteOutline />
          </IconButton>
        </Tooltip>
      )
    }
  ];

  return (
    <Stack spacing={4}>
      <Divider />
      <Typography variant="h2" sx={{ color: '#1976d2', textAlign: 'center' }}>
        Item Table
      </Typography>
      <Divider />
      {rows && rows.length > 0 && (
        <div
          className="table-container"
          style={{ height: totalHeight, maxHeight: 360 }}
        >
          <DataGrid
            className="custom-grid"
            rowHeight={rowHeight}
            columns={columns}
            rows={rows}
            onRowsChange={(updatedRows) => {
              setRows(updatedRows);
              onRowsChange(updatedRows);
            }}
            direction="ltr"
            defaultColumnOptions={{
              resizable: true,
              sortable: true
            }}
          />
        </div>
      )}
    </Stack>
  );
};

export default ItemsTable;
