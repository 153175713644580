import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ITax } from '../../interface';
import {
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography
} from '@mui/material';
import { Check, SquareEditOutline } from 'mdi-material-ui';

interface ITaxes {
  defaultTax?: string;
  onChangeTax: (Tax: ITax) => void;
  disabled: boolean;
  source_of_supply: string;
  destination_of_supply: string;
}

const Taxes = ({
  defaultTax,
  onChangeTax,
  disabled,
  source_of_supply,
  destination_of_supply
}: ITaxes) => {
  const { taxes } = useSelector((state: any) => state.poSlice);
  const [editTaxes, setEditTaxes] = useState<boolean>(false);
  let filteredTax = taxes?.filter((item: any) =>
    source_of_supply === destination_of_supply
      ? item?.tax_type === 'tax_group'
      : item?.tax_type === 'tax'
  );

  const [tax, setTax] = useState<ITax>(
    filteredTax.find((item: ITax) => item?.tax_name === defaultTax) || taxes[0]
  );

  useEffect(() => {
    setTax(
      filteredTax.find((item: ITax) => item?.tax_name === defaultTax) ||
        filteredTax[0]
    );
  }, [defaultTax]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={2}
    >
      {editTaxes ? (
        <Select
          size="small"
          value={tax?.tax_name}
          onChange={(e: SelectChangeEvent) =>
            setTax(
              filteredTax.find(
                (item: ITax) => item?.tax_name === e.target.value
              )
            )
          }
        >
          {filteredTax.map((item: ITax) => (
            <MenuItem value={item?.tax_name}>{item?.tax_name}</MenuItem>
          ))}
        </Select>
      ) : (
        <Typography>{tax?.tax_name}</Typography>
      )}
      <IconButton
        color="primary"
        disabled={disabled}
        onClick={() => {
          setEditTaxes(!editTaxes);
          onChangeTax(tax);
        }}
      >
        {editTaxes ? <Check /> : <SquareEditOutline />}
      </IconButton>
    </Stack>
  );
};

export default Taxes;
