import { Box, Typography } from '@mui/material';
import POTable from './Table';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../hooks';
import { fetchPOS, sendPOReminders } from '../redux/actionCreator';
import { useSelector } from 'react-redux';
import { IParams } from '../interface';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IAgent } from '../../TaskManagementSystem/interface';
import { subDays } from 'date-fns';

const PurchaseOrdersTable = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { posList, isFetching } = useSelector((state: any) => state.poSlice);
  const [params, setParams] = useState<IParams>({
    skip: 0,
    limit: 10,
    filters: {}
  });
  const [isSendingMail, setIsSendingMail] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const rowsPerPage = 10;

  useEffect(() => {
    let query = searchParams.get('params'),
      queryParams = query ? JSON.parse(query) : params;
    dispatch(fetchPOS(queryParams));
    setParams(queryParams);
  }, [setSearchParams]);

  const updateUrl = (tempParams: IParams) => {
    navigate({
      pathname: '/purchase-orders',
      search: `?params=${encodeURIComponent(JSON.stringify(tempParams))}`
    });
  };

  const onPageChange = (pageNumber: number) => {
    const tempParams = JSON.parse(JSON.stringify(params));
    tempParams.skip = Boolean(pageNumber)
      ? params.skip + rowsPerPage
      : params.skip - rowsPerPage;
    setParams(tempParams);
    updateUrl(tempParams);
  };

  const sendPOReminder = (poIds: string[]) => {
    setIsSendingMail(true);
    dispatch(sendPOReminders(poIds)).then((res: any) =>
      setIsSendingMail(false)
    );
  };

  const onFilterPoNumber = (poNumber: string) => {
    const tempParams: IParams = JSON.parse(JSON.stringify(params));
    tempParams.skip = 0;
    if (poNumber === '') {
      if (tempParams?.filters?.poNumber) {
        delete tempParams.filters.poNumber;
      }
    } else tempParams.filters.poNumber = poNumber;
    setParams(tempParams);
    updateUrl(tempParams);
  };

  const onFilterCampaign = ({ _id, name }: { _id: string; name: string }) => {
    const tempParams: IParams = JSON.parse(JSON.stringify(params));
    tempParams.skip = 0;
    tempParams.filters.campaign = { _id, name };
    setParams(tempParams);
    updateUrl(tempParams);
  };

  const onFilterHasBills = (value: boolean) => {
    const tempParams: IParams = JSON.parse(JSON.stringify(params));
    tempParams.skip = 0;
    if (value) tempParams.filters.hasBill = true;
    else delete tempParams.filters.hasBill;
    setParams(tempParams);
    updateUrl(tempParams);
  };

  const onFilterRejectedBills = (value: boolean) => {
    const tempParams: IParams = JSON.parse(JSON.stringify(params));
    tempParams.skip = 0;
    if (value) tempParams.filters.hasRejectedBill = true;
    else delete tempParams.filters.hasRejectedBill;
    setParams(tempParams);
    updateUrl(tempParams);
  };

  const onFilterBrand = (brand: string) => {
    const tempParams: IParams = JSON.parse(JSON.stringify(params));
    tempParams.skip = 0;
    if (brand !== '') tempParams.filters.brandName = brand;
    else delete tempParams.filters.brandName;
    setParams(tempParams);
    updateUrl(tempParams);
  };

  const onFilterVendor = (vendor: string) => {
    const tempParams: IParams = JSON.parse(JSON.stringify(params));
    tempParams.skip = 0;
    if (vendor !== '') tempParams.filters.vendorName = vendor;
    else delete tempParams.filters.vendorName;
    setParams(tempParams);
    updateUrl(tempParams);
  };

  const onFilterCreatedBy = (agent: IAgent) => {
    const tempParams = JSON.parse(JSON.stringify(params));
    tempParams.skip = 0;
    if (agent) {
      const { _id, email } = agent;
      tempParams.filters.createdBy = [{ _id, email }];
    } else {
      delete tempParams?.filters?.createdBy;
    }
    setParams(tempParams);
    updateUrl(tempParams);
  };

  const onFilterStatus = (status: string) => {
    const tempParams: IParams = JSON.parse(JSON.stringify(params));
    tempParams.skip = 0;
    if (status === 'all') {
      if (tempParams.filters.status) delete tempParams.filters.status;
    } else tempParams.filters.status = status;
    updateUrl(tempParams);
  };
  const onFilterRequestedBy = (agent: IAgent) => {
    const tempParams = JSON.parse(JSON.stringify(params));
    tempParams.skip = 0;
    if (agent) {
      const { _id, email } = agent;
      tempParams.filters.requestedBy = [{ _id, email }];
    } else {
      delete tempParams?.filters?.requestedBy;
    }
    setParams(tempParams);
    updateUrl(tempParams);
  };

  const onVerticalFilter = (vertical: string) => {
    const tempParams: IParams = JSON.parse(JSON.stringify(params));
    tempParams.skip = 0;
    if (vertical === 'all') {
      if (tempParams.filters.vertical) delete tempParams.filters.vertical;
    } else tempParams.filters.vertical = vertical;
    updateUrl(tempParams);
  };

  const onFilterDueDate = (dates: { from: Date; to: Date }) => {
    const tempParams = JSON.parse(JSON.stringify(params));
    tempParams.skip = 0;
    if (dates)
      tempParams.filters = {
        ...tempParams.filters,
        dueFromDate: dates.from,
        dueToDate: dates.to
      };
    else {
      delete tempParams.filters.dueFromDate;
      delete tempParams?.filters?.dueToDate;
    }
    updateUrl(tempParams);
  };

  const resetDueDateFilter = () => {
    const tempParams = JSON.parse(JSON.stringify(params));
    tempParams.skip = 0;
    if (tempParams?.filters?.dueFromDate) {
      delete tempParams.filters.dueFromDate;
      delete tempParams?.filters?.dueToDate;
    }
    updateUrl(tempParams);
  };

  return (
    <Box p={4}>
      <Typography variant="h1" fontWeight={500} m={'20px 0px'}>
        PO Dashboard
      </Typography>
      <POTable
        posList={isFetching ? [] : posList}
        isFetching={isFetching}
        isSendingMail={isSendingMail}
        rowsPerPage={rowsPerPage}
        params={params}
        onPageChange={onPageChange}
        sendPOReminders={sendPOReminder}
        onFilterPoNumber={onFilterPoNumber}
        onFilterCampaign={onFilterCampaign}
        onFilterHasBills={onFilterHasBills}
        onFilterBrand={onFilterBrand}
        onFilterVendor={onFilterVendor}
        onFilterCreatedBy={onFilterCreatedBy}
        onFilterStatus={onFilterStatus}
        onFilterRequestedBy={onFilterRequestedBy}
        onVerticalFilter={onVerticalFilter}
        onFilterRejectedBills={onFilterRejectedBills}
        onFilterDueDate={onFilterDueDate}
        resetDueDateFilter={resetDueDateFilter}
      />
    </Box>
  );
};

export default PurchaseOrdersTable;
