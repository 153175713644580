import { IconButton, Stack, TextField, Typography } from '@mui/material';
import { Check, SquareEditOutline } from 'mdi-material-ui';
import { useState } from 'react';

interface IQuantity {
  defaultQuantity: number;
  onQuantityChange: (quantity: number) => void;
  disabled: boolean;
}

const Quantity = ({
  defaultQuantity,
  onQuantityChange,
  disabled
}: IQuantity) => {
  const [quantity, setQuantity] = useState(defaultQuantity);
  const [editQuantity, setEditQuantity] = useState<boolean>(false);
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={2}
    >
      {editQuantity ? (
        <TextField
          type="number"
          value={quantity}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setQuantity(Number(event.target.value))
          }
        />
      ) : (
        <Typography>{quantity}</Typography>
      )}
      <IconButton
        color="primary"
        disabled={disabled}
        onClick={() => {
          setEditQuantity(!editQuantity);
          onQuantityChange(quantity);
        }}
      >
        {editQuantity ? <Check /> : <SquareEditOutline />}
      </IconButton>
    </Stack>
  );
};

export default Quantity;
